import * as TYPES from './types';

// Get Engins
export const getEnginsRequest = (payload) => ({
    type: TYPES.GET_ENGINS_REQUEST,
    payload
});
export const getEnginsReceive = (payload) => ({
    type: TYPES.GET_ENGINS_RECEIVE,
    payload
});
// Get Remorques
export const getRemorquesRequest = (payload) => ({
    type: TYPES.GET_REMORQUES_REQUEST,
    payload
});
export const getRemorquesReceive = (payload) => ({
    type: TYPES.GET_REMORQUES_RECEIVE,
    payload
});
// Get Camion
export const getCamionsRequest = (payload) => ({
    type: TYPES.GET_CAMIONS_REQUEST,
    payload
});
export const getCamionsReceive = (payload) => ({
    type: TYPES.GET_CAMIONS_RECEIVE,
    payload
});
//Create Engin
export const createEnginRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_ENGIN_REQUEST,
    payload,
    closeModal
});
export const createEnginReceive = (payload) => ({
    type: TYPES.CREATE_ENGIN_RECEIVE,
    payload
});
//Update engin
export const updateEnginRequest = (payload, closeModal) => ({
    type: TYPES.UPDATE_ENGIN_REQUEST,
    payload,
    closeModal
});
export const updateEnginReceive = (payload) => ({
    type: TYPES.UPDATE_ENGIN_RECEIVE,
    payload
});
//Delete Engin
export const deleteEnginRequest = (id) => ({
    type: TYPES.DELETE_ENGIN_REQUEST,
    id
});
export const deleteEnginReceive = (id) => ({
    type: TYPES.DELETE_ENGIN_RECEIVE,
    id
});

// Get Historique de maintenance par engin id
export const getHistoriqueEnginRequest = (id) => ({
    type: TYPES.GET_HISTORIQUE_REQUEST,
    id
});
export const getHistoriqueEnginReceive = (id) => ({
    type: TYPES.GET_HISTORIQUE_RECEIVE,
    id
});

//Count Engin
export const countEnginRequest = (payload) => ({
    type: TYPES.COUNT_ENGIN_REQUEST,
    payload
});
export const countEnginReceive = (payload) => ({
    type: TYPES.COUNT_ENGIN_RECEIVE,
    payload
});

// Count Broken Engin
export const countBrokenEnginRequest = (payload) => ({
    type: TYPES.COUNT_BROKEN_ENGIN_REQUEST,
    payload
});
export const countBrokenEnginReceive = (payload) => ({
    type: TYPES.COUNT_BROKEN_ENGIN_RECEIVE,
    payload
});
