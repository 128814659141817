import { axiosInstance } from '../../network';

//Get Assurances
export const getAssurancesRequest = async (payload) => {
    console.log('rdfgg', payload);
    return await axiosInstance.get(`/Alertes/getAllAssurance/${payload.enginId}`, { params: payload });
};
//Create Assurance
export const createAssuranceRequest = async (payload) => {
    return await axiosInstance.post(`/Alertes/createAssurance/${payload.id}`, payload);
};
// Update Assurance
export const updateAssuranceRequest = async (payload) => {
    return await axiosInstance.put(`/Alertes/updateAssurance/${payload.id}`, payload);
};

//Get Taxes
export const getTaxesRequest = async (payload) => {
    return await axiosInstance.get(`/Alertes/getAllTaxe/${payload.id}`, { params: payload });
};
//Create Taxe
export const createTaxeRequest = async (payload) => {
    return await axiosInstance.post(`/Alertes/createTaxe/${payload.id}`, payload);
};
// Update Taxe
export const updateTaxeRequest = async (payload) => {
    return await axiosInstance.put(`/Alertes/updateTaxe/${payload.id}`, payload);
};

//Get VisiteTechniques
export const getVisiteTechniquesRequest = async (payload) => {
    return await axiosInstance.get(`/Alertes/getAllVisiteTechnique/${payload.id}`, { params: payload });
};
//Create VisiteTechnique
export const createVisiteTechniqueRequest = async (payload) => {
    return await axiosInstance.post(`/Alertes/createVisiteTechnique/${payload.id}`, payload);
};
// Update VisiteTechnique
export const updateVisiteTechniqueRequest = async (payload) => {
    return await axiosInstance.put(`/Alertes/updateVisiteTechnique/${payload.id}`, payload);
};

//Get Vidanges
export const getVidangesRequest = async (payload) => {
    return await axiosInstance.get(`/Alertes/getAllVidange/${payload.id}`, { params: payload });
};
//Create Vidange
export const createVidangeRequest = async (payload) => {
    return await axiosInstance.post(`/Alertes/createVidange/${payload.id}`, payload);
};

//update Vidange
export const updateVidangeRequest = async (payload) => {
    return await axiosInstance.patch(`/Alertes/updateVidange/${payload.id}`, payload);
};

//Get Roues
export const getRouesRequest = async (id) => {
    return await axiosInstance.get(`/Alertes/getAllRoues/${id}`);
};
//Create Roue
export const createRoueRequest = async (payload) => {
    return await axiosInstance.post(`/Alertes/createRoues/${payload.id}`, payload);
};

//update Roue
export const updateRoueRequest = async (payload) => {
    return await axiosInstance.patch(`/Alertes/updateRoues/${payload.id}`, payload);
};

// get all alertes
export const getAllAlertesRequest = async () => {
    return await axiosInstance.get(`/engin/alerts`);
};
