import * as TYPES from './types';

// Get assurances
export const getAssurancesRequest = (payload) => ({
    type: TYPES.GET_ASSURANCES_REQUEST,
    payload
});
export const getAssurancesReceive = (payload) => ({
    type: TYPES.GET_ASSURANCES_RECEIVE,
    payload
});
//Create assurance
export const createAssuranceRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_ASSURANCE_REQUEST,
    closeModal,
    payload
});
// update assurance
export const updateAssuranceRequest = (payload, closeModal) => ({
    type: TYPES.UPDATE_ASSURANCE_REQUEST,
    closeModal,
    payload
});
export const updateAssuranceReceive = (payload) => ({
    type: TYPES.UPDATE_ASSURANCE_RECEIVE,
    payload
});

export const createAssuranceReceive = (payload) => ({
    type: TYPES.CREATE_ASSURANCE_RECEIVE,
    payload
});

// Get Taxes
export const getTaxesRequest = (payload) => ({
    type: TYPES.GET_TAXES_REQUEST,
    payload
});
export const getTaxesReceive = (payload) => ({
    type: TYPES.GET_TAXES_RECEIVE,
    payload
});
//Create Taxe
export const createTaxeRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_TAXE_REQUEST,
    closeModal,
    payload
});
export const createTaxeReceive = (payload) => ({
    type: TYPES.CREATE_TAXE_RECEIVE,
    payload
});

// update Taxe
export const updateTaxeRequest = (payload, closeModal) => ({
    type: TYPES.UPDATE_TAXE_REQUEST,
    closeModal,
    payload
});
export const updateTaxeReceive = (payload) => ({
    type: TYPES.UPDATE_TAXE_RECEIVE,
    payload
});

// Get VisiteTechniques
export const getVisiteTechniquesRequest = (payload) => ({
    type: TYPES.GET_VISITETECHNIQUES_REQUEST,
    payload
});
export const getVisiteTechniquesReceive = (payload) => ({
    type: TYPES.GET_VISITETECHNIQUES_RECEIVE,
    payload
});

//Create VisiteTechnique

export const createVisiteTechniqueRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_VISITETECHNIQUE_REQUEST,
    closeModal,
    payload
});
export const createVisiteTechniquesReceive = (payload) => ({
    type: TYPES.CREATE_VISITETECHNIQUE_RECEIVE,
    payload
});

// update VisiteTechnique
export const updateVisiteTechniqueRequest = (payload, closeModal) => ({
    type: TYPES.UPDATE_VISITETECHNIQUE_REQUEST,
    closeModal,
    payload
});

export const updateVisiteTechniqueReceive = (payload) => ({
    type: TYPES.UPDATE_VISITETECHNIQUE_RECEIVE,
    payload
});

// Get Vidanges
export const getVidangesRequest = (payload) => ({
    type: TYPES.GET_VIDANGES_REQUEST,
    payload
});
export const getVidangesReceive = (payload) => ({
    type: TYPES.GET_VIDANGES_RECEIVE,
    payload
});
//Create Vidange
export const createVidangeRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_VIDANGE_REQUEST,
    closeModal,
    payload
});
export const createVidangeReceive = (payload) => ({
    type: TYPES.CREATE_VIDANGE_RECEIVE,
    payload
});

// update Vidange
export const updateVidangeRequest = (payload, closeModal) => ({
    type: TYPES.UPDATE_VIDANGE_REQUEST,
    closeModal,
    payload
});
export const updateVidangeReceive = (payload) => ({
    type: TYPES.UPDATE_VIDANGE_RECEIVE,
    payload
});

// Get Roues
export const getRouesRequest = (payload) => ({
    type: TYPES.GET_ROUES_REQUEST,
    payload
});
export const getRouesReceive = (payload) => ({
    type: TYPES.GET_ROUES_RECEIVE,
    payload
});
//Create Roue
export const createRoueRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_ROUE_REQUEST,
    closeModal,
    payload
});
export const createRoueReceive = (payload) => ({
    type: TYPES.CREATE_ROUE_RECEIVE,
    payload
});

// update Roue
export const updateRoueRequest = (payload, closeModal) => ({
    type: TYPES.UPDATE_ROUE_REQUEST,
    closeModal,
    payload
});
export const updateRoueReceive = (payload) => ({
    type: TYPES.UPDATE_ROUE_RECEIVE,
    payload
});

// get all alertes
export const getAlertesRequest = () => ({
    type: TYPES.GET_ALL_ALERTES_REQUEST
});

export const getAlertesReceive = (payload) => ({
    type: TYPES.GET_ALL_ALERTES_RECEIVE,
    payload
});
