//Get achats
export const GET_ACHAT_REQUEST = 'GET_ACHAT_REQUEST';
export const GET_ACHAT_RECEIVE = 'GET_ACHAT_RECEIVE';
//create achat
export const CREATE_ACHAT_REQUEST = 'CREATE_ACHAT_REQUEST';
export const CREATE_ACHAT_RECEIVE = 'CREATE_ACHAT_RECEIVE';
//update achat
export const EDIT_ACHAT_REQUEST = 'EDIT_ACHAT_REQUEST';
export const EDIT_ACHAT_RECEIVE = 'EDIT_ACHAT_RECEIVE';
//delete achat
export const DELETE_ACHAT_REQUEST = 'DELETE_ACHAT_REQUEST';
export const DELETE_ACHAT_RECEIVE = 'DELETE_ACHAT_RECEIVE';

// Edit achat status
export const EDIT_ACHAT_STATUS_REQUEST = 'EDIT_ACHAT_STATUS_REQUEST';
export const EDIT_ACHAT_STATUS_RECEIVE = 'EDIT_ACHAT_STATUS_RECEIVE';

// get total achat
export const GET_TOTAL_ACHAT_REQUEST = 'GET_TOTAL_ACHAT_REQUEST';
export const GET_TOTAL_ACHAT_RECEIVE = 'GET_TOTAL_ACHAT_RECEIVE';
