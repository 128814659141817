//Get chauffeurs
export const GET_CHAUFFEURS_REQUEST = 'GET_CHAUFFEURS_REQUEST';
export const GET_CHAUFFEURS_RECEIVE = 'GET_CHAUFFEURS_RECEIVE';
//create chauffeur
export const CREATE_CHAUFFEUR_REQUEST = 'CREATE_CHAUFFEUR_REQUEST';
export const CREATE_CHAUFFEUR_RECEIVE = 'CREATE_CHAUFFEUR_RECEIVE';
// update chauffeur
export const UPDATE_CHAUFFEUR_REQUEST = 'UPDATE_CHAUFFEUR_REQUEST';
export const UPDATE_CHAUFFEUR_RECEIVE = 'UPDATE_CHAUFFEUR_RECEIVE';
// delete chauffeur
export const DELETE_CHAUFFEUR_REQUEST = 'DELETE_CHAUFFEUR_REQUEST';
export const DELETE_CHAUFFEUR_RECEIVE = 'DELETE_CHAUFFEUR_RECEIVE';
