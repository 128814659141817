// assets

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
// constant
const icons = { PeopleAltIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const GestionCoducteurs = {
    id: 'GestionCoducteurs',
    roles: ['ADMIN', 'PLANNER'],
    type: 'group',
    children: [
        {
            id: 'GestionCoducteurs',
            title: 'Gestion des conducteurs',
            type: 'item',
            icon: icons.PeopleAltIcon,
            url: '/conducteurs',
            breadcrumbs: true
        }
    ]
};

export default GestionCoducteurs;
