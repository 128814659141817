// get cles
export const GET_CLESPEAGE_REQUEST = 'GET_CLESPEAGE_REQUEST';
export const GET_CLESPEAGE_RECEIVE = 'GET_CLESPEAGE_RECEIVE';
//create cle
export const CREATE_CLEPEAGE_REQUEST = 'CREATE_CLEPEAGE_REQUEST';
export const CREATE_CLEPEAGE_RECEIVE = 'CREATE_CLEPEAGE_RECEIVE';
// Edit cle
export const EDIT_CLEPEAGE_REQUEST = 'EDIT_CLEPEAGE_REQUEST';
export const EDIT_CLEPEAGE_RECEIVE = ' EDIT_CLEPEAGE_RECEIVE';
// delete cle
export const DELETE_CLEPEAGE_REQUEST = 'DELETE_CLEPEAGE_REQUEST';
export const DELETE_CLEPEAGE_RECEIVE = 'DELETE_CLEPEAGE_RECEIVE';
