import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get assurances
export function* getAssuranceSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getAssurancesRequest, payload);

        yield put(ACTIONS.getAssurancesReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

// Create assurance
export function* createAssuranceSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createAssuranceRequest, payload);
        yield put(ACTIONS.createAssuranceReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

// Update assurance
export function* updateAssuranceSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.updateAssuranceRequest, payload);
        yield put(ACTIONS.updateAssuranceReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Get taxes
export function* getTaxesSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getTaxesRequest, payload);

        yield put(ACTIONS.getTaxesReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

// Create taxe
export function* createTaxeSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createTaxeRequest, payload);
        yield put(ACTIONS.createTaxeReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

// update taxe
export function* updateTaxeSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.updateTaxeRequest, payload);
        yield put(ACTIONS.updateTaxeReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Get taxes
export function* getVisiteTechniquesSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getVisiteTechniquesRequest, payload);
        yield put(ACTIONS.getVisiteTechniquesReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

// Create taxe
export function* createVisiteTechniqueSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createVisiteTechniqueRequest, payload);
        yield put(ACTIONS.createVisiteTechniquesReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}
// update visite technique
export function* updateVisiteTechniqueSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.updateVisiteTechniqueRequest, payload);
        yield put(ACTIONS.updateVisiteTechniqueReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Get vidanges
export function* getVidangesSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getVidangesRequest, payload);
        yield put(ACTIONS.getVidangesReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

// Create vidange
export function* createVidangeSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createVidangeRequest, payload);
        yield put(ACTIONS.createVidangeReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

// update vidange
export function* updateVidangeSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.updateVidangeRequest, payload);

        yield put(ACTIONS.updateVidangeReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Get Roues
export function* getRouesSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getRouesRequest, payload);
        yield put(ACTIONS.getRouesReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

// Create Roue
export function* createRoueSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createRoueRequest, payload);
        yield put(ACTIONS.createRoueReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

// update Roue
export function* updateRoueSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.updateRoueRequest, payload);
        yield put(ACTIONS.updateRoueReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

// get all alertes
export function* getAlertesSaga() {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getAllAlertesRequest);
        yield put(ACTIONS.getAlertesReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* alertsSagas() {
    yield takeLatest(TYPES.GET_ASSURANCES_REQUEST, getAssuranceSaga);
    yield takeLatest(TYPES.CREATE_ASSURANCE_REQUEST, createAssuranceSaga);
    yield takeLatest(TYPES.UPDATE_ASSURANCE_REQUEST, updateAssuranceSaga);
    yield takeLatest(TYPES.GET_TAXES_REQUEST, getTaxesSaga);
    yield takeLatest(TYPES.CREATE_TAXE_REQUEST, createTaxeSaga);
    yield takeLatest(TYPES.UPDATE_TAXE_REQUEST, updateTaxeSaga);
    yield takeLatest(TYPES.GET_VISITETECHNIQUES_REQUEST, getVisiteTechniquesSaga);
    yield takeLatest(TYPES.CREATE_VISITETECHNIQUE_REQUEST, createVisiteTechniqueSaga);
    yield takeLatest(TYPES.UPDATE_VISITETECHNIQUE_REQUEST, updateVisiteTechniqueSaga);
    yield takeLatest(TYPES.GET_VIDANGES_REQUEST, getVidangesSaga);
    yield takeLatest(TYPES.CREATE_VIDANGE_REQUEST, createVidangeSaga);
    yield takeLatest(TYPES.UPDATE_VIDANGE_REQUEST, updateVidangeSaga);
    yield takeLatest(TYPES.GET_ROUES_REQUEST, getRouesSaga);
    yield takeLatest(TYPES.CREATE_ROUE_REQUEST, createRoueSaga);
    yield takeLatest(TYPES.UPDATE_ROUE_REQUEST, updateRoueSaga);
    yield takeLatest(TYPES.GET_ALL_ALERTES_REQUEST, getAlertesSaga);
}
