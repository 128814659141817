import { axiosInstance } from '../../network';

//Get achats
export const getAchatsRequest = async (payload) => {
    return await axiosInstance.get(`achats`, { params: payload });
};

//Create achat
export const createAchatRequest = async (payload) => {
    return await axiosInstance.post(`achats/createachat`, payload);
};
//Delete achat
export const deleteAchatRequest = async (id) => {
    return await axiosInstance.delete(`achats/delete/${id}`);
};

//Edit achat
export const editAchatRequest = async (payload) => {
    return await axiosInstance.patch(`achats/${payload.id}`, payload);
};
//Edit achat status
export const editAchatStatusRequest = async (payload) => {
    return await axiosInstance.patch(`achats/updateStatutAchat/${payload.id}`, payload);
};

//get total achat
export const getTotalAchatRequest = async (payload) => {
    return await axiosInstance.get(`achats/totalAchat`, { params: payload });
};
