import * as TYPES from './types';

const initialState = {
    fournisseurs_matiere_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    fournisseurs_matiere: {}
};

export default function enginsReducer(state = initialState, action) {
    switch (action.type) {
        //Get fournisseurs matiere
        case TYPES.GET_FOURNISSEUR_MATIERE_RECEIVE: {
            return {
                ...state,
                fournisseurs_matiere_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create fournisseur matiere
        case TYPES.CREATE_FOURNISSEUR_MATIERE_RECEIVE: {
            return {
                ...state,
                fournisseurs_matiere_list: [...state.fournisseurs_matiere_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }
        //Edit fournisseur matiere
        case TYPES.EDIT_FOURNISSEUR_MATIERE_RECEIVE: {
            return {
                ...state,
                matiere: action.payload,
                fournisseurs_matiere_list: state.fournisseurs_matiere_list.map((fournisseurs_matiere) => {
                    if (fournisseurs_matiere.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return fournisseurs_matiere;
                    }
                })
            };
        }
        //Delete fournisseur matiere
        case TYPES.DELETE_FOURNISSEUR_MATIERE_RECEIVE: {
            return {
                ...state,
                fournisseurs_matiere_list: state.fournisseurs_matiere_list.filter(
                    (fournisseurs_matiere) => fournisseurs_matiere.id !== action.id
                ),
                totalFiltred: state.totalFiltred - 1
            };
        }

        default:
            return state;
    }
}
