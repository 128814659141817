import Parc from './gestionParc';
import system from './system';
import Planification from './tourne';
import GestionCoducteurs from './gestionConducteurs';
import GestionCles from './gestionCles';
import dashboard from './dashboard';

const menuItems = {
    items: [{ ...dashboard }, { ...Parc }, { ...Planification }, { ...GestionCoducteurs }, { ...GestionCles }, { ...system }]
};

export default menuItems;
