import * as TYPES from './types';

// Get fournisseurs
export const getFournisseursRequest = (payload) => ({
    type: TYPES.GET_FOURNISSEURS_REQUEST,
    payload
});
export const getFournisseursReceive = (payload) => ({
    type: TYPES.GET_FOURNISSEURS_RECEIVE,
    payload
});
//Create fournisseur
export const createFournisseurRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_FOURNISSEUR_REQUEST,
    payload,
    closeModal
});
export const createFournisseurReceive = (payload) => ({
    type: TYPES.CREATE_FOURNISSEUR_RECEIVE,
    payload
});
//Edit fournisseur
export const editFournisseurRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_FOURNISSEUR_REQUEST,
    payload,
    closeModal
});
export const editFournisseurReceive = (payload) => ({
    type: TYPES.EDIT_FOURNISSEUR_RECEIVE,
    payload
});

//Delete fournisseur
export const deleteFournisseurRequest = (id) => ({
    type: TYPES.DELETE_FOURNISSEUR_REQUEST,
    id
});
export const deleteFournisseurReceive = (id) => ({
    type: TYPES.DELETE_FOURNISSEUR_RECEIVE,
    id
});

// Get fournisseur by id
export const getNomFournisseurRequest = (id) => ({
    type: TYPES.GET_FOURNISSEUR_REQUEST,
    id
});
export const getNomFournisseurReceive = (id) => ({
    type: TYPES.GET_FOURNISSEUR_RECEIVE,
    id
});
