import * as TYPES from './types';

const initialState = {
    vehicules_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    vehicule: {}
};

export default function vehiculesReducer(state = initialState, action) {
    switch (action.type) {
        //Get vehicules
        case TYPES.GET_VEHICULES_RECEIVE: {
            return {
                ...state,
                vehicules_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.length)
            };
        }

        default:
            return state;
    }
}
