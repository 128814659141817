import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get tourneInterene
export function* getTournesInterneSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getTourneInterneRequest, payload);

        yield put(ACTIONS.getTournesInterneReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

// Create tourneInterene
export function* createTourneInterneSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createTourneInterneRequest, payload);
        yield put(ACTIONS.createTourneInterneReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Edit tourneInterene
export function* editTourneInterneSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editTourneInterneRequest, payload);
        yield put(ACTIONS.editTourneInterneReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete tourneInterene
export function* deleteTourneInterneSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteTourneInterneRequest, id);
        yield put(ACTIONS.deleteTourneInterneReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* TourneInternesSagas() {
    yield takeLatest(TYPES.GET_TOURNESINTERNE_REQUEST, getTournesInterneSaga);
    yield takeLatest(TYPES.CREATE_TOURNEINTERNE_REQUEST, createTourneInterneSaga);
    yield takeLatest(TYPES.DELETE_TOURNEINTERNE_REQUEST, deleteTourneInterneSaga);
    yield takeLatest(TYPES.EDIT_TOURNEINTERNE_REQUEST, editTourneInterneSaga);
}
