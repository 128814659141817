// assets
import { IconDashboard, IconCoinEuro, IconAd2, IconAdOff, IconChecklist, IconTools } from '@tabler/icons';
import DashboardIcon from '@mui/icons-material/Dashboard';
// constant
const icons = { IconDashboard, IconCoinEuro, DashboardIcon, IconAd2, IconAdOff, IconChecklist, IconTools };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    roles: ['ADMIN', 'PARKMANAGER', 'PLANNER'],
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.DashboardIcon,
            breadcrumbs: true
        }
    ]
};

export default dashboard;
