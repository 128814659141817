import * as TYPES from './types';

const initialState = {
    matieres_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    matiere: {}
};

export default function enginsReducer(state = initialState, action) {
    switch (action.type) {
        //Get matieres
        case TYPES.GET_MATIERES_RECEIVE: {
            return {
                ...state,
                matieres_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create matiere
        case TYPES.CREATE_MATIERE_RECEIVE: {
            return {
                ...state,
                matieres_list: [...state.matieres_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }
        //Edit matiere
        case TYPES.EDIT_MATIERE_RECEIVE: {
            return {
                ...state,
                matiere: action.payload,
                matieres_list: state.matieres_list.map((matiere) => {
                    if (matiere.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return matiere;
                    }
                })
            };
        }
        //Delete matiere
        case TYPES.DELETE_MATIERE_RECEIVE: {
            return {
                ...state,
                matieres_list: state.matieres_list.filter((matiere) => matiere.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }

        default:
            return state;
    }
}
