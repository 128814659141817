import { axiosInstance } from '../../network';

//Get TourneInterne
export const getTourneInterneRequest = async (payload) => {
    return await axiosInstance.get(`TourneInterne`, { params: payload });
};
//Create TourneInterne
export const createTourneInterneRequest = async (payload) => {
    return await axiosInstance.post(`TourneInterne/create`, payload);
};

//Edit TourneInterne
export const editTourneInterneRequest = async (payload) => {
    return await axiosInstance.patch(`TourneInterne/${payload.id}`, payload);
};
//Delete TourneInterne
export const deleteTourneInterneRequest = async (id) => {
    return await axiosInstance.delete(`TourneInterne/delete/${id}`);
};
