import * as TYPES from './types';

// Get matieres
export const getMatieresRequest = (payload) => ({
    type: TYPES.GET_MATIERES_REQUEST,
    payload
});
export const getMatieresReceive = (payload) => ({
    type: TYPES.GET_MATIERES_RECEIVE,
    payload
});
//Create matiere
export const createMatiereRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_MATIERE_REQUEST,
    payload,
    closeModal
});
export const createMatiereReceive = (payload) => ({
    type: TYPES.CREATE_MATIERE_RECEIVE,
    payload
});
//Edit matiere
export const editMatiereRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_MATIERE_REQUEST,
    payload,
    closeModal
});
export const editMatiereReceive = (payload) => ({
    type: TYPES.EDIT_MATIERE_RECEIVE,
    payload
});

//Delete matiere
export const deleteMatiereRequest = (id) => ({
    type: TYPES.DELETE_MATIERE_REQUEST,
    id
});
export const deleteMatiereReceive = (id) => ({
    type: TYPES.DELETE_MATIERE_RECEIVE,
    id
});
