import * as TYPES from './types';

const initialState = {
    cles_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    cle: {}
};

export default function clesPeageReducer(state = initialState, action) {
    switch (action.type) {
        //Get cle peage
        case TYPES.GET_CLESPEAGE_RECEIVE: {
            return {
                ...state,
                cles_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create cle peage
        case TYPES.CREATE_CLEPEAGE_RECEIVE: {
            return {
                ...state,
                cles_list: [...state.cles_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }
        //Edit cle peage
        case TYPES.EDIT_CLEPEAGE_RECEIVE: {
            return {
                ...state,
                cle: action.payload,
                cles_list: state.cles_list.map((cle) => {
                    if (cle.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return cle;
                    }
                })
            };
        }
        //Delete cle peage
        case TYPES.DELETE_CLEPEAGE_RECEIVE: {
            return {
                ...state,
                cles_list: state.cles_list.filter((cle) => cle.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }

        default:
            return state;
    }
}
