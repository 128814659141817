import * as TYPES from './types';

const initialState = {
    engins_list: [],
    historique: [],
    camions_list: [],
    remorques_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltered: 0,
    engin: {},
    enginCount: 0,
    enginPanneCount: 0,
    selectBrokenEngins: []
};

export default function enginsReducer(state = initialState, action) {
    switch (action.type) {
        // count Engin

        case TYPES.COUNT_ENGIN_RECEIVE: {
            return {
                ...state,
                enginCount: action.payload
            };
        }

        // count Engin Panne
        case TYPES.COUNT_BROKEN_ENGIN_RECEIVE: {
            return {
                ...state,
                enginPanneCount: action.payload
            };
        }

        // Get Engins
        case TYPES.GET_ENGINS_RECEIVE: {
            return {
                ...state,
                engins_list: action?.payload?.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total),
                selectBrokenEngins: action?.payload?.data.filter((engin) => engin.statut === 'BROKEN')
            };
        }

        // Get Camions
        case TYPES.GET_CAMIONS_RECEIVE: {
            return {
                ...state,
                camions_list: action?.payload?.data
            };
        }

        // Get Remorques
        case TYPES.GET_REMORQUES_RECEIVE: {
            return {
                ...state,
                remorques_list: action?.payload?.data
            };
        }

        // Create engin
        case TYPES.CREATE_ENGIN_RECEIVE: {
            return {
                ...state,
                engins_list: [...state.engins_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }

        // Update engin
        case TYPES.UPDATE_ENGIN_RECEIVE: {
            return {
                ...state,
                engins_list: state.engins_list.map((engin) => {
                    if (engin.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return engin;
                    }
                })
            };
        }

        // Delete engin
        case TYPES.DELETE_ENGIN_RECEIVE: {
            return {
                ...state,
                engins_list: state.engins_list.filter((engin) => engin.id !== action.id),
                totalFiltered: state.totalFiltered - 1
            };
        }

        // Get Historique de maintenance par engin id
        case TYPES.GET_HISTORIQUE_RECEIVE: {
            return {
                ...state,
                historique: action.id
            };
        }

        default:
            return state;
    }
}
