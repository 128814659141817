//Get piece
export const GET_PIECES_REQUEST = 'GET_PIECES_REQUEST';
export const GET_PIECES_RECEIVE = 'GET_PIECES_RECEIVE';
//create piece
export const CREATE_PIECE_REQUEST = 'CREATE_PIECE_REQUEST';
export const CREATE_PIECE_RECEIVE = 'CREATE_PIECE_RECEIVE';
//update piece
export const EDIT_PIECE_REQUEST = 'EDIT_PIECE_REQUEST';
export const EDIT_PIECE_RECEIVE = 'EDIT_PIECE_RECEIVE';

//delete piece
export const DELETE_PIECE_REQUEST = 'DELETE_PIECE_REQUEST';
export const DELETE_PIECE_RECEIVE = 'DELETE_PIECE_RECEIVE';

// upload file
export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_RECEIVE = 'UPLOAD_FILE_RECEIVE';
export const UPLOAD_FILE_PROGRESS = 'UPLOAD_FILE_PROGRESS';
