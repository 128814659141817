import * as TYPES from './types';

// Get tourneExterne
export const getTournesExterneRequest = (payload) => ({
    type: TYPES.GET_TOURNESEXTERNE_REQUEST,
    payload
});
export const getTournesExterneReceive = (payload) => ({
    type: TYPES.GET_TOURNESEXTERNE_RECEIVE,
    payload
});
//Create tourneExterne
export const createTourneExterneRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_TOURNEEXTERNE_REQUEST,
    payload,
    closeModal
});
export const createTourneExterneReceive = (payload) => ({
    type: TYPES.CREATE_TOURNEEXTERNE_RECEIVE,
    payload
});
//Edit tourneExterne
export const editTourneExterneRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_TOURNEEXTERNE_REQUEST,
    payload,
    closeModal
});
export const editTourneExterneReceive = (payload) => ({
    type: TYPES.EDIT_TOURNEEXTERNE_RECEIVE,
    payload
});

//Edit statustourneExterne
export const editTourneRequest = (id, status) => ({
    type: TYPES.EDIT_TOURNE_STATUS_REQUEST,
    id,
    status
});

export const editTourneReceive = (payload) => ({
    type: TYPES.EDIT_TOURNE_STATUS_RECEIVE,
    payload
});

//Delete tourneExterne
export const deleteTourneExterneRequest = (id) => ({
    type: TYPES.DELETE_TOURNEEXTERNE_REQUEST,
    id
});
export const deleteTourneExterneReceive = (id) => ({
    type: TYPES.DELETE_TOURNEEXTERNE_RECEIVE,
    id
});
