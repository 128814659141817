// get tourneExterne
export const GET_TOURNESEXTERNE_REQUEST = 'GET_TOURNEEXTERNE_REQUEST';
export const GET_TOURNESEXTERNE_RECEIVE = 'GET_TOURNEEXTERNE_RECEIVE';
//create tourneExterne
export const CREATE_TOURNEEXTERNE_REQUEST = 'CREATE_TOURNEEXTERNE_REQUEST';
export const CREATE_TOURNEEXTERNE_RECEIVE = 'CREATE_TOURNEEXTERNE_RECEIVE';
// Edit tourneExterne
export const EDIT_TOURNEEXTERNE_REQUEST = 'EDIT_TOURNEEXTERNE_REQUEST';
export const EDIT_TOURNEEXTERNE_RECEIVE = ' EDIT_TOURNEEXTERNE_RECEIVE';
// delete tourneExterne
export const DELETE_TOURNEEXTERNE_REQUEST = 'DELETE_TOURNEEXTERNE_REQUEST';
export const DELETE_TOURNEEXTERNE_RECEIVE = 'DELETE_TOURNEEXTERNE_RECEIVE';
// edit status tourneExterne

export const EDIT_TOURNE_STATUS_REQUEST = 'EDIT_TOURNE_STATUS_REQUEST';
export const EDIT_TOURNE_STATUS_RECEIVE = 'EDIT_TOURNE_STATUS_RECEIVE';
