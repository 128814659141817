import * as TYPES from './types';

const initialState = {
    chauffeurs_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    chauffeur: {}
};

export default function chauffeursReducer(state = initialState, action) {
    switch (action.type) {
        //Get chauffeurs
        case TYPES.GET_CHAUFFEURS_RECEIVE: {
            return {
                ...state,
                chauffeurs_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create chauffeur
        case TYPES.CREATE_CHAUFFEUR_RECEIVE: {
            return {
                ...state,
                chauffeurs_list: [...state.chauffeurs_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }

        //Update chauffeur
        case TYPES.UPDATE_CHAUFFEUR_RECEIVE: {
            return {
                ...state,
                chauffeur: action.payload,
                chauffeurs_list: state.chauffeurs_list.map((chauffeur) => {
                    if (chauffeur.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return chauffeur;
                    }
                })
            };
        }
        //Delete chauffeur
        case TYPES.DELETE_CHAUFFEUR_RECEIVE: {
            return {
                ...state,
                chauffeurs_list: state.chauffeurs_list.filter((chauffeur) => chauffeur.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }

        default:
            return state;
    }
}
