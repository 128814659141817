import * as TYPES from './types';

// Get pieces
export const getPiecesRequest = (payload) => ({
    type: TYPES.GET_PIECES_REQUEST,
    payload
});
export const getPiecesReceive = (payload) => ({
    type: TYPES.GET_PIECES_RECEIVE,
    payload
});

//Create Piece
export const createPieceRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_PIECE_REQUEST,
    payload,
    closeModal
});
export const createPieceReceive = (payload) => ({
    type: TYPES.CREATE_PIECE_RECEIVE,
    payload
});
//Edit Piece
export const editPieceRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_PIECE_REQUEST,
    payload,
    closeModal
});
export const editPieceReceive = (payload) => ({
    type: TYPES.EDIT_PIECE_RECEIVE,
    payload
});
//Delete Piece
export const deletePieceRequest = (id) => ({
    type: TYPES.DELETE_PIECE_REQUEST,
    id
});
export const deletePieceReceive = (id) => ({
    type: TYPES.DELETE_PIECE_RECEIVE,
    id
});
