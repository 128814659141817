import { axiosInstance } from '../../network';

//Get matieres
export const getMatieresRequest = async (payload) => {
    return await axiosInstance.get(`matiere`, { params: payload });
};
//Create matiere
export const createMatiereRequest = async (payload) => {
    return await axiosInstance.post(`matiere/createMatiere`, payload);
};

//Edit matiere
export const editMatiereRequest = async (payload) => {
    return await axiosInstance.patch(`matiere/${payload.id}`, payload);
};
//Delete matiere
export const deleteMatiereRequest = async (id) => {
    return await axiosInstance.delete(`matiere/delete/${id}`);
};
