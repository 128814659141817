// get tourneinterne
export const GET_TOURNESINTERNE_REQUEST = 'GET_TOURNEINTERNE_REQUEST';
export const GET_TOURNESINTERNE_RECEIVE = 'GET_TOURNEINTERNE_RECEIVE';
//create tourneinterne
export const CREATE_TOURNEINTERNE_REQUEST = 'CREATE_TOURNEINTERNE_REQUEST';
export const CREATE_TOURNEINTERNE_RECEIVE = 'CREATE_TOURNEINTERNE_RECEIVE';
// Edit tourneinterne
export const EDIT_TOURNEINTERNE_REQUEST = 'EDIT_TOURNEINTERNE_REQUEST';
export const EDIT_TOURNESINTERNE_RECEIVE = ' EDIT_TOURNEINTERNE_RECEIVE';
// delete tourneinterne
export const DELETE_TOURNEINTERNE_REQUEST = 'DELETE_TOURNEINTERNE_REQUEST';
export const DELETE_TOURNEINTERNE_RECEIVE = 'DELETE_TOURNEINTERNE_RECEIVE';
