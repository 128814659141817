import * as TYPES from './types';

// Get tourneInterne
export const getTournesInterneRequest = (payload) => ({
    type: TYPES.GET_TOURNESINTERNE_REQUEST,
    payload
});
export const getTournesInterneReceive = (payload) => ({
    type: TYPES.GET_TOURNESINTERNE_RECEIVE,
    payload
});
//Create tourneInterne
export const createTourneInterneRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_TOURNEINTERNE_REQUEST,
    payload,
    closeModal
});
export const createTourneInterneReceive = (payload) => ({
    type: TYPES.CREATE_TOURNEINTERNE_RECEIVE,
    payload
});
//Edit tourneInterne
export const editTourneInterneRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_TOURNEINTERNE_REQUEST,
    payload,
    closeModal
});
export const editTourneInterneReceive = (payload) => ({
    type: TYPES.EDIT_TOURNESINTERNE_RECEIVE,
    payload
});

//Delete tourneInterne
export const deleteTourneInterneRequest = (id) => ({
    type: TYPES.DELETE_TOURNEINTERNE_REQUEST,
    id
});
export const deleteTourneInterneReceive = (id) => ({
    type: TYPES.DELETE_TOURNEINTERNE_RECEIVE,
    id
});
