/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const PiecesPage = Loadable(lazy(() => import('views/pages/piece/PiecesPage')));
const Engins = Loadable(lazy(() => import('views/pages/engins/EnginsPage')));
const Fournisseurs = Loadable(lazy(() => import('views/pages/fournisseur/FournisseursPage')));
const AchatsPage = Loadable(lazy(() => import('views/pages/achat/AchatsPage')));
const MaintenancePage = Loadable(lazy(() => import('views/pages/maintenance/MaintenancePage')));
const AlertesPage = Loadable(lazy(() => import('views/pages/Alerte/AlertesPage')));
const MatieresPage = Loadable(lazy(() => import('views/pages/matiere/MatieresPage')));
const ClientsPage = Loadable(lazy(() => import('views/pages/client/ClientsPage')));
const ChauffeursPage = Loadable(lazy(() => import('views/pages/chauffeur/ChauffeurPage')));
const FournisseurMatieresPage = Loadable(lazy(() => import('views/pages/fournisseur_matiere/FournisseurMatieresPage')));
const ClesPage = Loadable(lazy(() => import('views/pages/ClePeage/ClesPeage')));
const TournesInternePage = Loadable(lazy(() => import('views/pages/TourneInterne/TourneInternePage')));
const TournesExternePage = Loadable(lazy(() => import('views/pages/TourneExterne/TourneExternePage')));
//Users routing
const InternalUsers = Loadable(lazy(() => import('views/pages/users/InternalUsers/InternalUsers')));

// Settings Routes
const EventsPage = Loadable(lazy(() => import('views/pages/events/EventsPage')));
const Settings = Loadable(lazy(() => import('views/pages/settings')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

{
    /* <AuthGuard>
            <MainLayout />
        </AuthGuard> */
}
const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            element: <DashboardDefault />
        },
        {
            path: 'engins',
            element: <Engins />
        },
        {
            path: 'piece',
            element: <PiecesPage />
        },
        {
            path: 'fournisseurs',
            element: <Fournisseurs />
        },
        {
            path: 'achats',
            element: <AchatsPage />
        },

        {
            path: 'maintenance',
            element: <MaintenancePage />
        },
        {
            path: 'alertes',
            element: <AlertesPage />
        },
        {
            path: 'matiere',
            element: <MatieresPage />
        },
        {
            path: 'client',
            element: <ClientsPage />
        },
        {
            path: 'conducteurs',
            element: <ChauffeursPage />
        },
        {
            path: 'clePeage',
            element: <ClesPage />
        },
        {
            path: 'FournisseurMatiere',
            element: <FournisseurMatieresPage />
        },
        {
            path: 'tourneInterne',
            element: <TournesInternePage />
        },

        {
            path: 'tourneExterne',
            element: <TournesExternePage />
        },

        {
            path: 'users',
            element: <InternalUsers />
        },

        {
            path: 'events',
            element: <EventsPage />
        },

        {
            path: 'settings',
            element: <Settings />
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
