import { axiosInstance } from '../../network';

//Get fournisseurs
export const getFournisseursRequest = async (payload) => {
    return await axiosInstance.get(`fournisseur`, { params: payload });
};
//Create fournisseur
export const createFournisseurRequest = async (payload) => {
    return await axiosInstance.post(`fournisseur/createFournisseur`, payload);
};

//Edit fournisseur
export const editFournisseurRequest = async (payload) => {
    return await axiosInstance.patch(`fournisseur/${payload.id}`, payload);
};
//Delete fournisseur
export const deleteFournisseurRequest = async (id) => {
    return await axiosInstance.delete(`fournisseur/delete/${id}`);
};

//Delete fournisseur
export const getNomFournisseurRequest = async (id) => {
    return await axiosInstance.delete(`fournisseur/nom/${id}`);
};
