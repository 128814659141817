import * as TYPES from './types';

const initialState = {
    assurances_list: [],
    taxes_list: [],
    visiteTechniques_list: [],
    vidanges_list: [],
    roues_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    alertes_list: []
};

export default function alertsReducer(state = initialState, action) {
    switch (action.type) {
        // get all alerts
        case TYPES.GET_ALL_ALERTES_RECEIVE: {
            return {
                ...state,
                alertes_list: action?.payload
            };
        }

        //Get assurances
        case TYPES.GET_ASSURANCES_RECEIVE: {
            return {
                ...state,
                assurances_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create assurance
        case TYPES.CREATE_ASSURANCE_RECEIVE: {
            return {
                ...state,
                assurances_list: [...state.assurances_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }

        // update assurance
        case TYPES.UPDATE_ASSURANCE_RECEIVE: {
            return {
                ...state,
                assurances_list: state.assurances_list.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }
                    return item;
                })
            };
        }

        //Get Taxes
        case TYPES.GET_TAXES_RECEIVE: {
            return {
                ...state,
                taxes_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create Taxe
        case TYPES.CREATE_TAXE_RECEIVE: {
            return {
                ...state,
                taxes_list: [...state.taxes_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }

        // update Taxe
        case TYPES.UPDATE_TAXE_RECEIVE: {
            return {
                ...state,
                taxes_list: state.taxes_list.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }
                    return item;
                })
            };
        }

        //Get VisiteTechniques
        case TYPES.GET_VISITETECHNIQUES_RECEIVE: {
            return {
                ...state,
                visiteTechniques_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create VisiteTechnique
        case TYPES.CREATE_VISITETECHNIQUE_RECEIVE: {
            return {
                ...state,
                visiteTechniques_list: [...state.visiteTechniques_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }

        // update VisiteTechnique
        case TYPES.UPDATE_VISITETECHNIQUE_RECEIVE: {
            return {
                ...state,
                visiteTechniques_list: state.visiteTechniques_list.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }
                    return item;
                })
            };
        }

        //Get Vidanges
        case TYPES.GET_VIDANGES_RECEIVE: {
            return {
                ...state,
                vidanges_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create Vidange
        case TYPES.CREATE_VIDANGE_RECEIVE: {
            return {
                ...state,
                vidanges_list: [...state.vidanges_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }
        // update Vidange

        case TYPES.UPDATE_VIDANGE_RECEIVE: {
            return {
                ...state,
                vidanges_list: state.vidanges_list.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }
                    return item;
                })
            };
        }

        //Get Roues
        case TYPES.GET_ROUES_RECEIVE: {
            return {
                ...state,
                roues_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create Roue
        case TYPES.CREATE_ROUE_RECEIVE: {
            return {
                ...state,
                roues_list: [...state.roues_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }

        //update Roue
        case TYPES.UPDATE_ROUE_RECEIVE: {
            return {
                ...state,

                roues_list: state.roues_list.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }
                    return item;
                })
            };
        }

        default:
            return state;
    }
}
