// get matieres
export const GET_FOURNISSEUR_MATIERE_REQUEST = 'GET_FOURNISSEUR_MATIERE_REQUEST';
export const GET_FOURNISSEUR_MATIERE_RECEIVE = 'GET_FOURNISSEUR_MATIERE_RECEIVE';
//create matiere
export const CREATE_FOURNISSEUR_MATIERE_REQUEST = 'CREATE_FOURNISSEUR_MATIERE_REQUEST';
export const CREATE_FOURNISSEUR_MATIERE_RECEIVE = 'CREATE_FOURNISSEUR_MATIERE_RECEIVE';
// Edit matiere
export const EDIT_FOURNISSEUR_MATIERE_REQUEST = 'EDIT_FOURNISSEUR_MATIERE_REQUEST';
export const EDIT_FOURNISSEUR_MATIERE_RECEIVE = ' EDIT_FOURNISSEUR_MATIERE_RECEIVE';
// delete matiere
export const DELETE_FOURNISSEUR_MATIERE_REQUEST = 'DELETE_FOURNISSEUR_MATIERE_REQUEST';
export const DELETE_FOURNISSEUR_MATIERE_RECEIVE = 'DELETE_FOURNISSEUR_MATIERE_RECEIVE';
