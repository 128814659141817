import * as TYPES from './types';

// Get cles
export const getClesPeageRequest = (payload) => ({
    type: TYPES.GET_CLESPEAGE_REQUEST,
    payload
});
export const getClesPeageReceive = (payload) => ({
    type: TYPES.GET_CLESPEAGE_RECEIVE,
    payload
});
//Create cle
export const createClePeageRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_CLEPEAGE_REQUEST,
    payload,
    closeModal
});
export const createClePeageReceive = (payload) => ({
    type: TYPES.CREATE_CLEPEAGE_RECEIVE,
    payload
});
//Edit cle
export const editClePeageRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_CLEPEAGE_REQUEST,
    payload,
    closeModal
});
export const editClePeageReceive = (payload) => ({
    type: TYPES.EDIT_CLEPEAGE_RECEIVE,
    payload
});

//Delete cle
export const deleteClePeageRequest = (id) => ({
    type: TYPES.DELETE_CLEPEAGE_REQUEST,
    id
});
export const deleteClePeageReceive = (id) => ({
    type: TYPES.DELETE_CLEPEAGE_RECEIVE,
    id
});
