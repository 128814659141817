import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get Pieces
export function* getPiecesSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getPiecesRequest, payload);
        yield put(ACTIONS.getPiecesReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError('Erreur lors de la récupération des piéces');
    }
}

// Create Piece
export function* createPieceSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createPieceRequest, payload);
        yield put(ACTIONS.createPieceReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Edit Piece
export function* editPieceSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editPieceRequest, payload);
        yield put(ACTIONS.editPieceReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Piece
export function* deletePieceSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deletePieceRequest, id);
        yield put(ACTIONS.deletePieceReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* piecesSagas() {
    yield takeLatest(TYPES.GET_PIECES_REQUEST, getPiecesSaga);
    yield takeLatest(TYPES.CREATE_PIECE_REQUEST, createPieceSaga);
    yield takeLatest(TYPES.DELETE_PIECE_REQUEST, deletePieceSaga);
    yield takeLatest(TYPES.EDIT_PIECE_REQUEST, editPieceSaga);
}
