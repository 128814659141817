import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get chauffeurs
export function* getChauffeursSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getChauffeursRequest, payload);

        yield put(ACTIONS.getChauffeursReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError('Erreur lors de la récupération des chauffeurs');
    }
}

// Create Chauffeur
export function* createChauffeurSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createChauffeurRequest, payload);
        yield put(ACTIONS.createChauffeurReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}
//Update chauffeur
export function* updateChauffeurSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.updateChauffeurRequest, payload);
        yield put(ACTIONS.updateChauffeurReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}
//Delete Chauffeur
export function* deleteChauffeurSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteChauffeurRequest, id);
        yield put(ACTIONS.deleteChauffeurReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* chauffeursSagas() {
    yield takeLatest(TYPES.GET_CHAUFFEURS_REQUEST, getChauffeursSaga);
    yield takeLatest(TYPES.CREATE_CHAUFFEUR_REQUEST, createChauffeurSaga);
    yield takeLatest(TYPES.DELETE_CHAUFFEUR_REQUEST, deleteChauffeurSaga);
    yield takeLatest(TYPES.UPDATE_CHAUFFEUR_REQUEST, updateChauffeurSaga);
}
