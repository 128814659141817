import { axiosInstance } from '../../network';

//Get Engins
export const getEnginsRequest = async (payload) => {
    return await axiosInstance.get(`engin`, { params: payload });
};
//Get Camion
export const getCamionsRequest = async (payload) => {
    return await axiosInstance.get(`engin/camion`, { params: payload });
};
//Get Remorque
export const getRemorquesRequest = async (payload) => {
    return await axiosInstance.get(`engin/remorque`, { params: payload });
};
//Create Engin
export const createEnginRequest = async (payload) => {
    return await axiosInstance.post(`engin/createEngin`, payload);
};
//Delete Engin
export const deleteEnginRequest = async (id) => {
    return await axiosInstance.delete(`engin/delete/${id}`);
};

// Get Historique de maintenance par engin id
export const getHistoriqueEnginRequest = async (id) => {
    return await axiosInstance.get(`/engin/${id}/historiqueMaintenance`);
};
//Update engin
export const updateEnginRequest = async (payload) => {
    return await axiosInstance.patch(`engin/${payload.id}`, payload);
};

// Count engin
export const countEnginRequest = async () => {
    return await axiosInstance.get(`engin/count`);
};
// Count Broken Engin
export const countBrokenEnginRequest = async () => {
    return await axiosInstance.get(`engin/enginStatutBroken/count`);
};
