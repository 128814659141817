import * as TYPES from './types';

const initialState = {
    maintenances_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    maintenance: {}
};

export default function maintenacesReducer(state = initialState, action) {
    switch (action.type) {
        //Get maintenances
        case TYPES.GET_MAINTENANCES_RECEIVE: {
            return {
                ...state,
                maintenances_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }

        //Edit maintenance
        case TYPES.EDIT_MAINTENANCE_RECEIVE: {
            return {
                ...state,
                maintenance: action.payload,
                maintenances_list: state.maintenances_list.map((maintenance) => {
                    if (maintenance.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return maintenance;
                    }
                })
            };
        }
        //Create maintenance
        case TYPES.CREATE_MAINTENANCE_RECEIVE: {
            return {
                ...state,
                maintenances_list: [...state.maintenances_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }

        //Delete maintenances
        case TYPES.DELETE_MAINTENANCE_RECEIVE: {
            return {
                ...state,
                maintenances_list: state.maintenances_list.filter((maintenance) => maintenance.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }

        default:
            return state;
    }
}
