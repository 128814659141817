import * as TYPES from './types';

const initialState = {
    fournisseurs_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltered: 0,
    fournisseur: {}
};

export default function enginsReducer(state = initialState, action) {
    switch (action.type) {
        //Get fournisseurs
        case TYPES.GET_FOURNISSEURS_RECEIVE: {
            return {
                ...state,
                fournisseurs_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create fournissseur
        case TYPES.CREATE_FOURNISSEUR_RECEIVE: {
            return {
                ...state,
                fournisseurs_list: [...state.fournisseurs_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }
        //Edit fournissseur
        case TYPES.EDIT_FOURNISSEUR_RECEIVE: {
            return {
                ...state,
                fournisseur: action.payload,
                fournisseurs_list: state.fournisseurs_list.map((fournisseur) => {
                    if (fournisseur.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return fournisseur;
                    }
                })
            };
        }
        //Delete fournisseur
        case TYPES.DELETE_FOURNISSEUR_RECEIVE: {
            return {
                ...state,
                fournisseurs_list: state.fournisseurs_list.filter((fournisseur) => fournisseur.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }
        //get fournisseur
        case TYPES.GET_FOURNISSEUR_RECEIVE: {
            return {
                ...state,
                fournisseur: action.payload
            };
        }

        default:
            return state;
    }
}
