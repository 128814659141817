import { axiosInstance } from '../../network';

//Get maintenances
export const getMaintenanceRequest = async (payload) => {
    return await axiosInstance.get(`maintenance`, { params: payload });
};

//Create maintenance
export const createMaintenanceRequest = async (payload) => {
    return await axiosInstance.post(`maintenance/createMaintenance`, payload);
};
//Edit maintenance
export const editMaintenanceRequest = async (payload) => {
    return await axiosInstance.patch(`maintenance/${payload.id}`, payload);
};
//Delete maintenance
export const deleteMaintenanceRequest = async (id) => {
    return await axiosInstance.delete(`maintenance/delete/${id}`);
};
