// get clients
export const GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST';
export const GET_CLIENTS_RECEIVE = 'GET_CLIENTS_RECEIVE';
//create client
export const CREATE_CLIENT_REQUEST = 'CREATE_CLIENT_REQUEST';
export const CREATE_CLIENT_RECEIVE = 'CREATE_CLIENT_RECEIVE';
// Edit client
export const EDIT_CLIENT_REQUEST = 'EDIT_CLIENT_REQUEST';
export const EDIT_CLIENT_RECEIVE = ' EDIT_CLIENT_RECEIVE';
// delete client
export const DELETE_CLIENT_REQUEST = 'DELETE_CLIENT_REQUEST';
export const DELETE_CLIENT_RECEIVE = 'DELETE_CLIENT_RECEIVE';
