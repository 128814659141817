// get matieres
export const GET_MATIERES_REQUEST = 'GET_MATIERES_REQUEST';
export const GET_MATIERES_RECEIVE = 'GET_MATIERES_RECEIVE';
//create matiere
export const CREATE_MATIERE_REQUEST = 'CREATE_MATIERE_REQUEST';
export const CREATE_MATIERE_RECEIVE = 'CREATE_MATIERE_RECEIVE';
// Edit matiere
export const EDIT_MATIERE_REQUEST = 'EDIT_MATIERE_REQUEST';
export const EDIT_MATIERE_RECEIVE = ' EDIT_MATIERE_RECEIVE';
// delete matiere
export const DELETE_MATIERE_REQUEST = 'DELETE_MATIERE_REQUEST';
export const DELETE_MATIERE_RECEIVE = 'DELETE_MATIERE_RECEIVE';
