import SellIcon from '@mui/icons-material/Sell';
// constant
const icons = { SellIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const GestionCles = {
    id: 'GestionCles',
    roles: ['ADMIN', 'PLANNER'],
    type: 'group',
    children: [
        {
            id: 'GestionCles',
            title: 'Clés De Péage',
            type: 'item',
            icon: icons.SellIcon,
            url: '/clePeage',
            breadcrumbs: true
        }
    ]
};

export default GestionCles;
