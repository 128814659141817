import * as TYPES from './types';

const initialState = {
    TourneExterne_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    TourneExterne: {}
};

export default function tournesExterneReducer(state = initialState, action) {
    switch (action.type) {
        //Get TourneExterne
        case TYPES.GET_TOURNESEXTERNE_RECEIVE: {
            return {
                ...state,
                TourneExterne_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create TourneExterne
        case TYPES.CREATE_TOURNEEXTERNE_RECEIVE: {
            return {
                ...state,
                TourneExterne_list: [...state.TourneExterne_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }
        case TYPES.EDIT_TOURNEEXTERNE_RECEIVE: {
            const updatedTourneList = state.TourneExterne_list.map((TourneExterne) => {
                if (TourneExterne.id === action.payload.id) {
                    return action.payload;
                } else {
                    return TourneExterne;
                }
            });

            return {
                ...state,
                TourneExterne_list: updatedTourneList
            };
        }

        case TYPES.EDIT_TOURNE_STATUS_RECEIVE: {
            const updatedTourneList = state.TourneExterne_list.map((TourneExterne) => {
                if (TourneExterne.id === action.payload.id) {
                    return action.payload;
                } else {
                    return TourneExterne;
                }
            });

            return {
                ...state,
                TourneExterne_list: updatedTourneList
            };
        }

        //Delete TourneExterne
        case TYPES.DELETE_TOURNEEXTERNE_RECEIVE: {
            return {
                ...state,
                TourneExterne_list: state.TourneExterne_list,
                TourneExterne_list: state.TourneExterne_list.filter((tourne) => tourne.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }

        default:
            return state;
    }
}
