// material-ui
import { Typography } from '@mui/material';
import React from 'react';
// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import getUserDataRequest from 'store/users/actions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state.users);
    const role = userData ? userData.role : 'user';
    const filteredItems = menuItem.items.filter((item) => {
        // Condition to filter menu items based on role
        if (item.roles && item.roles.includes(role)) {
            return true;
        }
        return false;
    });

    const navItems = filteredItems.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            case 'link':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
