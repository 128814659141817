import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get fournisseurs
export function* getFournisseursSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getFournisseursRequest, payload);

        yield put(ACTIONS.getFournisseursReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError('Erreur lors de la récupération des fournisseurs');
    }
}

// Create fournisseur
export function* createFournisseurSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createFournisseurRequest, payload);
        yield put(ACTIONS.createFournisseurReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Edit fournisseur
export function* editFournisseurSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editFournisseurRequest, payload);
        yield put(ACTIONS.editFournisseurReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete fournisseur
export function* deleteFournisseurSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteFournisseurRequest, id);
        yield put(ACTIONS.deleteFournisseurReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Get nom fournisseur
export function* getFournisseurSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getNomFournisseurRequest, payload);
        yield put(ACTIONS.getNomFournisseurReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* fournisseursSagas() {
    yield takeLatest(TYPES.GET_FOURNISSEURS_REQUEST, getFournisseursSaga);
    yield takeLatest(TYPES.CREATE_FOURNISSEUR_REQUEST, createFournisseurSaga);
    yield takeLatest(TYPES.DELETE_FOURNISSEUR_REQUEST, deleteFournisseurSaga);
    yield takeLatest(TYPES.GET_FOURNISSEUR_REQUEST, getFournisseurSaga);
    yield takeLatest(TYPES.EDIT_FOURNISSEUR_REQUEST, editFournisseurSaga);
}
