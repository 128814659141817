// assets

import ListAltIcon from '@mui/icons-material/ListAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
// constant
const icons = {
    ListAltIcon,
    SettingsIcon,
    PeopleAltIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const system = {
    id: 'system',
    title: '',
    roles: ['ADMIN'],
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'Gestion des utilisateurs',
            type: 'item',
            url: '/users',
            icon: icons.PeopleAltIcon,
            breadcrumbs: true
        },
        {
            id: 'events',
            title: 'Jounral des événements',
            type: 'item',
            url: '/events',
            icon: icons.ListAltIcon,
            breadcrumbs: true
        }
    ]
};

export default system;
