import * as TYPES from './types';

// Get clients
export const getClientsRequest = (payload) => ({
    type: TYPES.GET_CLIENTS_REQUEST,
    payload
});
export const getClientsReceive = (payload) => ({
    type: TYPES.GET_CLIENTS_RECEIVE,
    payload
});
//Create client
export const createClientRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_CLIENT_REQUEST,
    payload,
    closeModal
});
export const createClientReceive = (payload) => ({
    type: TYPES.CREATE_CLIENT_RECEIVE,
    payload
});
//Edit client
export const editClientRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_CLIENT_REQUEST,
    payload,
    closeModal
});
export const editClientReceive = (payload) => ({
    type: TYPES.EDIT_CLIENT_RECEIVE,
    payload
});

//Delete client
export const deleteClientRequest = (id) => ({
    type: TYPES.DELETE_CLIENT_REQUEST,
    id
});
export const deleteClientReceive = (id) => ({
    type: TYPES.DELETE_CLIENT_RECEIVE,
    id
});
