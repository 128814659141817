import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get fournisseurs matiere
export function* getFournisseursMatiereSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getFournisseurMatieresRequest, payload);

        yield put(ACTIONS.getFournisseurMatieresReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError('Erreur lors de la récupération des fournisseurs');
    }
}

// Create fournisseur matiere
export function* createFournisseursMatiereSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createFournisseurMatiereRequest, payload);
        yield put(ACTIONS.createFournisseurMatiereReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Edit fournisseur matiere
export function* editFournisseursMatiereSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editFournisseurMatiereRequest, payload);
        yield put(ACTIONS.editFournisseurMatiereReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete fournisseur matiere
export function* deleteFournisseursMatiereSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteFournisseurMatiereRequest, id);
        yield put(ACTIONS.deleteFournisseurMatiereReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* fournisseurs_matieresSagas() {
    yield takeLatest(TYPES.GET_FOURNISSEUR_MATIERE_REQUEST, getFournisseursMatiereSaga);
    yield takeLatest(TYPES.CREATE_FOURNISSEUR_MATIERE_REQUEST, createFournisseursMatiereSaga);
    yield takeLatest(TYPES.DELETE_FOURNISSEUR_MATIERE_REQUEST, deleteFournisseursMatiereSaga);
    yield takeLatest(TYPES.EDIT_FOURNISSEUR_MATIERE_REQUEST, editFournisseursMatiereSaga);
}
