// get fournisseurs
export const GET_FOURNISSEURS_REQUEST = 'GET_FOURNISSEURS_REQUEST';
export const GET_FOURNISSEURS_RECEIVE = 'GET_FOURNISSEURS_RECEIVE';
//create fournisseur
export const CREATE_FOURNISSEUR_REQUEST = 'CREATE_FOURNISSEUR_REQUEST';
export const CREATE_FOURNISSEUR_RECEIVE = 'CREATE_FOURNISSEUR_RECEIVE';
// Edit fournisseur
export const EDIT_FOURNISSEUR_REQUEST = 'EDIT_FOURNISSEUR_REQUEST';
export const EDIT_FOURNISSEUR_RECEIVE = ' EDIT_FOURNISSEUR_RECEIVE';
// delete fournisseur
export const DELETE_FOURNISSEUR_REQUEST = 'DELETE_FOURNISSEUR_REQUEST';
export const DELETE_FOURNISSEUR_RECEIVE = 'DELETE_FOURNISSEUR_RECEIVE';
// get fournisseur by id
export const GET_FOURNISSEUR_REQUEST = 'GET_FOURNISSEUR_REQUEST';
export const GET_FOURNISSEUR_RECEIVE = 'GET_FOURNISSEUR_RECEIVE';
