import * as TYPES from './types';

// Get fournisseur matieres
export const getFournisseurMatieresRequest = (payload) => ({
    type: TYPES.GET_FOURNISSEUR_MATIERE_REQUEST,
    payload
});
export const getFournisseurMatieresReceive = (payload) => ({
    type: TYPES.GET_FOURNISSEUR_MATIERE_RECEIVE,
    payload
});
//Create fournisseur matiere
export const createFournisseurMatiereRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_FOURNISSEUR_MATIERE_REQUEST,
    payload,
    closeModal
});
export const createFournisseurMatiereReceive = (payload) => ({
    type: TYPES.CREATE_FOURNISSEUR_MATIERE_RECEIVE,
    payload
});
//Edit fournisseur matiere
export const editFournisseurMatiereRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_FOURNISSEUR_MATIERE_REQUEST,
    payload,
    closeModal
});
export const editFournisseurMatiereReceive = (payload) => ({
    type: TYPES.EDIT_FOURNISSEUR_MATIERE_RECEIVE,
    payload
});

//Delete fournisseur matiere
export const deleteFournisseurMatiereRequest = (id) => ({
    type: TYPES.DELETE_FOURNISSEUR_MATIERE_REQUEST,
    id
});
export const deleteFournisseurMatiereReceive = (id) => ({
    type: TYPES.DELETE_FOURNISSEUR_MATIERE_RECEIVE,
    id
});
