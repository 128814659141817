import { all } from 'redux-saga/effects';
import { authSagas } from './auth/sagas';
import { usersSagas } from './users/sagas';
import { notificationsSagas } from './notifications/sagas';
import { eventsSagas } from './events/sagas';
import { themeSagas } from './theme/sagas';
import { toolsSagas } from './tools/sagas';

import { enginsSagas } from './engins/sagas';
import { piecesSagas } from './piece/sagas';
import { fournisseursSagas } from './fournisseur/sagas';
import { achatsSagas } from './achat/sagas';
import { maintenancesSagas } from './maintenance/sagas';
import { chauffeursSagas } from './chauffeur/sagas';
import { vehiculesSagas } from './vehicule/sagas';
import { matieresSagas } from './matiere/sagas';
import { fournisseurs_matieresSagas } from './fournisseur_matiere/sagas';
import { clientsSagas } from './client/sagas';
import { clesSagas } from './ClePeage/sagas';
import { TourneInternesSagas } from './TourneInterne/sagas';
import { TourneExternesSagas } from './TourneExterne/sagas';
import { alertsSagas } from './alertes/sagas';
export function* watchSagas() {
    yield all([
        themeSagas(),
        authSagas(),
        usersSagas(),
        notificationsSagas(),
        eventsSagas(),
        toolsSagas(),
        enginsSagas(),
        piecesSagas(),
        fournisseursSagas(),
        achatsSagas(),
        maintenancesSagas(),
        chauffeursSagas(),
        vehiculesSagas(),
        matieresSagas(),
        fournisseurs_matieresSagas(),
        clientsSagas(),
        clesSagas(),
        TourneInternesSagas(),
        TourneExternesSagas(),
        alertsSagas()
    ]);
}
