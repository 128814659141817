import { axiosInstance } from '../../network';
//tools
//Get Tools
export const getToolsRequest = async (payload) => {
    return await axiosInstance.get(`api/tools`, { params: payload });
};
//Get Tool
export const getToolRequest = async (payload) => {
    return await axiosInstance.get(`api/tools/${payload}`);
};
//Create Tool
export const createToolRequest = async (payload) => {
    return await axiosInstance.post(`api/tools`, payload);
};
//Update Tool
export const updateToolRequest = async (payload) => {
    return await axiosInstance.patch(`api/tools/${payload.id}`, payload);
};
//Delete Tool
export const deleteToolRequest = async (id) => {
    return await axiosInstance.delete(`api/tools/${id}`);
};
