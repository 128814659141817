//Tools
//Get Tools
export const GET_TOOLS_REQUEST = 'GET_TOOLS_REQUEST';
export const GET_TOOLS_RECEIVE = 'GET_TOOLS_RECEIVE';
//Get Tool
export const GET_TOOL_REQUEST = 'GET_TOOL_REQUEST';
export const GET_TOOL_RECEIVE = 'GET_TOOL_RECEIVE';
//Create Tool
export const CREATE_TOOL_REQUEST = 'CREATE_TOOL_REQUEST';
export const CREATE_TOOL_RECEIVE = 'CREATE_TOOL_RECEIVE';
//Update Tool
export const UPDATE_TOOL_REQUEST = 'UPDATE_TOOL_REQUEST';
export const UPDATE_TOOL_RECEIVE = 'UPDATE_TOOL_RECEIVE';
//Delete Tool
export const DELETE_TOOL_REQUEST = 'DELETE_TOOL_REQUEST';
export const DELETE_TOOL_RECEIVE = 'DELETE_TOOL_RECEIVE';
