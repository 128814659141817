// assets

import HandymanIcon from '@mui/icons-material/Handyman';
const icons = {
    HandymanIcon
};

// ==============================|| DASHBOARD MENU collapseS ||============================== //

const Parc = {
    id: 'parc',
    title: '',
    type: 'group',
    roles: ['ADMIN', 'PARKMANAGER'],
    children: [
        {
            id: 'Liste des engins',
            title: 'Gestion De Parc',
            type: 'collapse',
            icon: icons.HandymanIcon,
            breadcrumbs: true,
            children: [
                {
                    id: 'engin',
                    title: 'Engins',
                    type: 'item',
                    url: '/engins'
                },
                {
                    id: 'liste des pièce de rechange',
                    title: 'Pièce de rechange',
                    type: 'item',
                    url: '/piece'
                },
                {
                    id: 'Liste des fournisseurs P.R',
                    title: 'Fournisseur P.R',
                    type: 'item',

                    url: '/fournisseurs'
                },
                {
                    id: 'les achats',
                    title: 'Achats',
                    type: 'item',

                    url: '/achats'
                },
                {
                    id: 'Maintenance',
                    title: 'Maintenance',
                    type: 'item',

                    url: '/maintenance'
                },
                {
                    id: 'Alerte',
                    title: 'Alerte',
                    type: 'item',
                    url: '/alertes'
                }
            ]
        }
    ]
};

export default Parc;
