import * as TYPES from './types';

const initialState = {
    clients_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    client: {}
};

export default function enginsReducer(state = initialState, action) {
    switch (action.type) {
        //Get clients
        case TYPES.GET_CLIENTS_RECEIVE: {
            return {
                ...state,
                clients_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create client
        case TYPES.CREATE_CLIENT_RECEIVE: {
            return {
                ...state,
                clients_list: [...state.clients_list, action.payload],
                totalFiltred: state.totalFiltred + 1
            };
        }
        //Edit client
        case TYPES.EDIT_CLIENT_RECEIVE: {
            return {
                ...state,
                client: action.payload,
                clients_list: state.clients_list.map((client) => {
                    if (client.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return client;
                    }
                })
            };
        }
        //Delete client
        case TYPES.DELETE_CLIENT_RECEIVE: {
            return {
                ...state,
                clients_list: state.clients_list.filter((client) => client.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }

        default:
            return state;
    }
}
