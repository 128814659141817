import * as TYPES from './types';

const initialState = {
    pieces_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    piece: {}
};

export default function piecesReducer(state = initialState, action) {
    switch (action.type) {
        //Get Pieces
        case TYPES.GET_PIECES_RECEIVE: {
            return {
                ...state,
                pieces_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        case TYPES.CREATE_PIECE_RECEIVE: {
            const newPieces = action.payload.filter((newPiece) => !state.pieces_list.includes(newPiece));
            return {
                ...state,
                pieces_list: [...state.pieces_list, ...newPieces],
                totalFiltred: state.totalFiltred + 1
            };
        }

        //Edit Piece
        case TYPES.EDIT_PIECE_RECEIVE: {
            return {
                ...state,
                piece: action.payload,
                pieces_list: state.pieces_list.map((piece) => {
                    if (piece.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return piece;
                    }
                })
            };
        }

        //Delete Piece
        case TYPES.DELETE_PIECE_RECEIVE: {
            return {
                ...state,
                pieces_list: state.pieces_list.filter((piece) => piece.id !== action.id),
                totalFiltred: state.totalFiltred - 1
            };
        }

        default:
            return state;
    }
}
