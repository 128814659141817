/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.theme);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll />
                <div style={{ marginTop: '70px' }}>
                    <Routes />
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
