import { combineReducers } from 'redux';
import theme from './theme/reducer';
import auth from './auth/reducer';
import users from './users/reducer';
import loader from './loader/reducer';
import snackbar from './snackbar/reducer';
import notifications from './notifications/reducer';
import events from './events/reducer';
import tools from './tools/reducer';
import loading from './loading/reducer';
import enginReducer from './engins/reducer';
import pieces from './piece/reducer';
import fournisseurs from './fournisseur/reducer';
import achat from './achat/reducer';
import maintenance from './maintenance/reducer';
import chauffeur from './chauffeur/reducer';
import vehicule from './vehicule/reducer';
import matiere from './matiere/reducer';
import fournisseurMatiere from './fournisseur_matiere/reducer';
import clients from './client/reducer';
import cles from './ClePeage/reducer';
import tournesInterne from './TourneInterne/reducer';
import tournesExterne from './TourneExterne/reducer';
import alerts from './alertes/reducer';
export default combineReducers({
    theme,
    snackbar,
    loader,
    auth,
    users,
    notifications,
    events,
    loading,
    enginReducer,
    pieces,
    fournisseurs,
    achat,
    maintenance,
    chauffeur,
    vehicule,
    matiere,
    fournisseurMatiere,
    clients,
    cles,
    tournesInterne,
    tournesExterne,
    alerts
});
