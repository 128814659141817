import { axiosInstance } from '../../network';

//Get chauffeurs
export const getChauffeursRequest = async (payload) => {
    return await axiosInstance.get(`chauffeur`, { params: payload });
};

//Create chauffeur
export const createChauffeurRequest = async (payload) => {
    return await axiosInstance.post(`chauffeur/create`, payload);
};
//Update chauffeur
export const updateChauffeurRequest = async (payload) => {
    return await axiosInstance.patch(`chauffeur/${payload.id}`, payload);
};
//Delete chauffeur
export const deleteChauffeurRequest = async (id) => {
    return await axiosInstance.delete(`chauffeur/delete/${id}`);
};
