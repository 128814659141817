import { axiosInstance } from '../../network';

//Get tourneExterne
export const getTournesExterneRequest = async (payload) => {
    return await axiosInstance.get(`tourne`, { params: payload });
};
//Create tourneExterne
export const createTourneExterneRequest = async (payload) => {
    return await axiosInstance.post(`tourne/create`, payload);
};

//Edit tourneExterne
export const editTourneExterneRequest = async (payload) => {
    return await axiosInstance.patch(`tourne/${payload.id}`, payload);
};

//Edit tourneStatus
export const editTourneStatusRequest = async (id, status) => {
    return await axiosInstance.put(`tourne/${id}/status`, { status });
};

//Delete tourneExterne
export const deleteTourneExterneRequest = async (id) => {
    return await axiosInstance.delete(`tourne/delete/${id}`);
};
