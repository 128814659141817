// get assurances
export const GET_ASSURANCES_REQUEST = 'GET_ASSURANCES_REQUEST';
export const GET_ASSURANCES_RECEIVE = 'GET_ASSURANCES_RECEIVE';
//create assurance
export const CREATE_ASSURANCE_REQUEST = 'CREATE_ASSURANCE_REQUEST';
export const CREATE_ASSURANCE_RECEIVE = 'CREATE_ASSURANCE_RECEIVE';
//update assurance
export const UPDATE_ASSURANCE_REQUEST = 'UPDATE_ASSURANCE_REQUEST';
export const UPDATE_ASSURANCE_RECEIVE = 'UPDATE_ASSURANCE_RECEIVE';

// get taxes
export const GET_TAXES_REQUEST = 'GET_TAXES_REQUEST';
export const GET_TAXES_RECEIVE = 'GET_TAXES_RECEIVE';
//create taxe
export const CREATE_TAXE_REQUEST = 'CREATE_TAXE_REQUEST';
export const CREATE_TAXE_RECEIVE = 'CREATE_TAXE_RECEIVE';

//update taxe
export const UPDATE_TAXE_REQUEST = 'UPDATE_TAXE_REQUEST';
export const UPDATE_TAXE_RECEIVE = 'UPDATE_TAXE_RECEIVE';

//get visiteTechniques
export const GET_VISITETECHNIQUES_REQUEST = 'GET_VISITETECHNIQUES_REQUEST';
export const GET_VISITETECHNIQUES_RECEIVE = 'GET_VISITETECHNIQUES_RECEIVE';
//create visiteTechnique
export const CREATE_VISITETECHNIQUE_REQUEST = 'CREATE_VISITETECHNIQUE_REQUEST';
export const CREATE_VISITETECHNIQUE_RECEIVE = 'CREATE_VISITETECHNIQUE_RECEIVE';

//update visiteTechnique
export const UPDATE_VISITETECHNIQUE_REQUEST = 'UPDATE_VISITETECHNIQUE_REQUEST';
export const UPDATE_VISITETECHNIQUE_RECEIVE = 'UPDATE_VISITETECHNIQUE_RECEIVE';

//get vidanges
export const GET_VIDANGES_REQUEST = 'GET_VIDANGES_REQUEST';
export const GET_VIDANGES_RECEIVE = 'GET_VIDANGES_RECEIVE';
//create vidange
export const CREATE_VIDANGE_REQUEST = 'CREATE_VIDANGE_REQUEST';
export const CREATE_VIDANGE_RECEIVE = 'CREATE_VIDANGE_RECEIVE';

//update vidange
export const UPDATE_VIDANGE_REQUEST = 'UPDATE_VIDANGE_REQUEST';
export const UPDATE_VIDANGE_RECEIVE = 'UPDATE_VIDANGE_RECEIVE';

//get roues
export const GET_ROUES_REQUEST = 'GET_ROUES_REQUEST';
export const GET_ROUES_RECEIVE = 'GET_ROUES_RECEIVE';
//create roue
export const CREATE_ROUE_REQUEST = 'CREATE_ROUE_REQUEST';
export const CREATE_ROUE_RECEIVE = 'CREATE_ROUE_RECEIVE';

//update roue
export const UPDATE_ROUE_REQUEST = 'UPDATE_ROUE_REQUEST';
export const UPDATE_ROUE_RECEIVE = 'UPDATE_ROUE_RECEIVE';

// get all alertes
export const GET_ALL_ALERTES_REQUEST = 'GET_ALL_ALERTES_REQUEST';
export const GET_ALL_ALERTES_RECEIVE = 'GET_ALL_ALERTES_RECEIVE';
