import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get achats
export function* getAchatsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getAchatsRequest, payload);

        yield put(ACTIONS.getAchatsReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}
// Create achat
export function* createAchatSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createAchatRequest, payload);
        yield put(ACTIONS.createAchatReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Edit achat
export function* editAchatSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editAchatRequest, payload);
        yield put(ACTIONS.editAchatReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Edit achat status
export function* editAchatStatusSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editAchatStatusRequest, payload);
        yield put(ACTIONS.editAchatStatusReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete achat
export function* deleteAchatSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteAchatRequest, id);
        yield put(ACTIONS.deleteAchatReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}
// get total achat
export function* getTotalAchatSaga() {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getTotalAchatRequest);
        yield put(ACTIONS.getTotalAchatReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* achatsSagas() {
    yield takeLatest(TYPES.GET_ACHAT_REQUEST, getAchatsSaga);
    yield takeLatest(TYPES.CREATE_ACHAT_REQUEST, createAchatSaga);
    yield takeLatest(TYPES.DELETE_ACHAT_REQUEST, deleteAchatSaga);
    yield takeLatest(TYPES.EDIT_ACHAT_REQUEST, editAchatSaga);
    yield takeLatest(TYPES.EDIT_ACHAT_STATUS_REQUEST, editAchatStatusSaga);
    yield takeLatest(TYPES.GET_TOTAL_ACHAT_REQUEST, getTotalAchatSaga);
}
