import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get cles
export function* getClesPeageSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getClesPeageRequest, payload);

        yield put(ACTIONS.getClesPeageReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

// Create cle
export function* createClePeageSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createClePeageRequest, payload);
        yield put(ACTIONS.createClePeageReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Edit cle
export function* editClePeageSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editClePeageRequest, payload);
        yield put(ACTIONS.editClePeageReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete cle
export function* deleteClePeageSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteClePeageRequest, id);
        yield put(ACTIONS.deleteClePeageReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* clesSagas() {
    yield takeLatest(TYPES.GET_CLESPEAGE_REQUEST, getClesPeageSaga);
    yield takeLatest(TYPES.CREATE_CLEPEAGE_REQUEST, createClePeageSaga);
    yield takeLatest(TYPES.DELETE_CLEPEAGE_REQUEST, deleteClePeageSaga);
    yield takeLatest(TYPES.EDIT_CLEPEAGE_REQUEST, editClePeageSaga);
}
