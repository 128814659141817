import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get matieres
export function* getMatieresSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getMatieresRequest, payload);

        yield put(ACTIONS.getMatieresReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError('Erreur lors de la récupération des matieres');
    }
}

// Create matiere
export function* createMatiereSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createMatiereRequest, payload);
        yield put(ACTIONS.createMatiereReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Edit matiere
export function* editMatiereSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editMatiereRequest, payload);
        yield put(ACTIONS.editMatiereReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete matiere
export function* deleteMatiereSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteMatiereRequest, id);
        yield put(ACTIONS.deleteMatiereReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* matieresSagas() {
    yield takeLatest(TYPES.GET_MATIERES_REQUEST, getMatieresSaga);
    yield takeLatest(TYPES.CREATE_MATIERE_REQUEST, createMatiereSaga);
    yield takeLatest(TYPES.DELETE_MATIERE_REQUEST, deleteMatiereSaga);
    yield takeLatest(TYPES.EDIT_MATIERE_REQUEST, editMatiereSaga);
}
