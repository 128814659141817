//Get Engins
export const GET_ENGINS_REQUEST = 'GET_ENGINS_REQUEST';
export const GET_ENGINS_RECEIVE = 'GET_ENGINS_RECEIVE';
//Get Camions
export const GET_CAMIONS_REQUEST = 'GET_CAMIONS_REQUEST';
export const GET_CAMIONS_RECEIVE = 'GET_CAMIONS_RECEIVE';
//Get Remorques
export const GET_REMORQUES_REQUEST = 'GET_REMORQUES_REQUEST';
export const GET_REMORQUES_RECEIVE = 'GET_REMORQUES_RECEIVE';
// Create engin
export const CREATE_ENGIN_REQUEST = 'CREATE_ENGIN_REQUEST';
export const CREATE_ENGIN_RECEIVE = 'CREATE_ENGIN_RECEIVE';
//Update Engin
export const UPDATE_ENGIN_REQUEST = 'UPDATE_ENGIN_REQUEST';
export const UPDATE_ENGIN_RECEIVE = 'UPDATE_ENGIN_RECEIVE';
//Delete Engin
export const DELETE_ENGIN_REQUEST = 'DELETE_ENGIN_REQUEST';
export const DELETE_ENGIN_RECEIVE = 'DELETE_ENGIN_RECEIVE';
//Historique
export const GET_HISTORIQUE_REQUEST = 'GET_HISTORIQUE_REQUEST';
export const GET_HISTORIQUE_RECEIVE = 'GET_HISTORIQUE_RECEIVE';

// Count engin
export const COUNT_ENGIN_REQUEST = 'COUNT_ENGIN_REQUEST';
export const COUNT_ENGIN_RECEIVE = 'COUNT_ENGIN_RECEIVE';

// Count Broken Engin
export const COUNT_BROKEN_ENGIN_REQUEST = 'COUNT_BROKEN_ENGIN_REQUEST';
export const COUNT_BROKEN_ENGIN_RECEIVE = 'COUNT_BROKEN_ENGIN_RECEIVE';
