import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get tourneInterene
export function* getTournesExterneSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getTournesExterneRequest, payload);

        yield put(ACTIONS.getTournesExterneReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

// Create tourneInterene
export function* createTourneExterneSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createTourneExterneRequest, payload);
        yield put(ACTIONS.createTourneExterneReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Edit tourneInterene
export function* editTourneExterneSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editTourneExterneRequest, payload);
        yield put(ACTIONS.editTourneExterneReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Edit tournestatus

export function* editTourneStatutSaga({ id, status }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editTourneStatusRequest, id, status);
        yield put(ACTIONS.editTourneReceive(data));
        yield put(setLoadingStatus(false));
    } catch (error) {
        dispatchSnackbarError(error.response?.data);
    }
}

//Delete tourneInterene
export function* deleteTourneExterneSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteTourneExterneRequest, id);
        yield put(ACTIONS.deleteTourneExterneReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* TourneExternesSagas() {
    yield takeLatest(TYPES.GET_TOURNESEXTERNE_REQUEST, getTournesExterneSaga);
    yield takeLatest(TYPES.CREATE_TOURNEEXTERNE_REQUEST, createTourneExterneSaga);
    yield takeLatest(TYPES.DELETE_TOURNEEXTERNE_REQUEST, deleteTourneExterneSaga);
    yield takeLatest(TYPES.EDIT_TOURNEEXTERNE_REQUEST, editTourneExterneSaga);
    yield takeLatest(TYPES.EDIT_TOURNE_STATUS_REQUEST, editTourneStatutSaga);
}
