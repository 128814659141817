import { axiosInstance } from '../../network';

//Get clients
export const getClientsRequest = async (payload) => {
    return await axiosInstance.get(`client`, { params: payload });
};
//Create client
export const createClientRequest = async (payload) => {
    return await axiosInstance.post(`client/create`, payload);
};

//Edit client
export const editClientRequest = async (payload) => {
    return await axiosInstance.patch(`client/${payload.id}`, payload);
};
//Delete client
export const deleteClientRequest = async (id) => {
    return await axiosInstance.delete(`client/delete/${id}`);
};
