import { axiosInstance } from '../../network';

//Get fournisseur matieres
export const getFournisseurMatieresRequest = async (payload) => {
    return await axiosInstance.get(`fournisseurMatiere`, { params: payload });
};
//Create fournisseur matiere
export const createFournisseurMatiereRequest = async (payload) => {
    return await axiosInstance.post(`fournisseurMatiere/create`, payload);
};

//Edit fournisseur matiere
export const editFournisseurMatiereRequest = async (payload) => {
    return await axiosInstance.patch(`fournisseurMatiere/${payload.id}`, payload);
};
//Delete fournisseur matiere
export const deleteFournisseurMatiereRequest = async (id) => {
    return await axiosInstance.delete(`fournisseurMatiere/delete/${id}`);
};
