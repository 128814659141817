import * as TYPES from './types';

// Get chauffeurs
export const getChauffeursRequest = (payload) => ({
    type: TYPES.GET_CHAUFFEURS_REQUEST,
    payload
});
export const getChauffeursReceive = (payload) => ({
    type: TYPES.GET_CHAUFFEURS_RECEIVE,
    payload
});

//Create chauffeur
export const createChauffeurRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_CHAUFFEUR_REQUEST,
    payload,
    closeModal
});
export const createChauffeurReceive = (payload) => ({
    type: TYPES.CREATE_CHAUFFEUR_RECEIVE,
    payload
});

//Update chauffeur
export const updateChauffeurRequest = (payload, closeModal) => ({
    type: TYPES.UPDATE_CHAUFFEUR_REQUEST,
    payload,
    closeModal
});
export const updateChauffeurReceive = (payload) => ({
    type: TYPES.UPDATE_CHAUFFEUR_RECEIVE,
    payload
});

//Delete chauffeur
export const deleteChauffeurRequest = (id) => ({
    type: TYPES.DELETE_CHAUFFEUR_REQUEST,
    id
});
export const deleteChauffeurReceive = (id) => ({
    type: TYPES.DELETE_CHAUFFEUR_RECEIVE,
    id
});
