import * as TYPES from './types';

// Get achats
export const getAchatsRequest = (payload) => ({
    type: TYPES.GET_ACHAT_REQUEST,
    payload
});
export const getAchatsReceive = (payload) => ({
    type: TYPES.GET_ACHAT_RECEIVE,
    payload
});

//Create achat
export const createAchatRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_ACHAT_REQUEST,
    payload,
    closeModal
});
export const createAchatReceive = (payload) => ({
    type: TYPES.CREATE_ACHAT_RECEIVE,
    payload
});
//Edit achat
export const editAchatRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_ACHAT_REQUEST,
    payload,
    closeModal
});
export const editAchatReceive = (payload) => ({
    type: TYPES.EDIT_ACHAT_RECEIVE,
    payload
});

// Edit achat status
export const editAchatStatusRequest = (payload, closeModal) => ({
    type: TYPES.EDIT_ACHAT_STATUS_REQUEST,
    payload,
    closeModal
});
export const editAchatStatusReceive = (payload) => ({
    type: TYPES.EDIT_ACHAT_STATUS_RECEIVE,
    payload
});

//Delete achat
export const deleteAchatRequest = (id) => ({
    type: TYPES.DELETE_ACHAT_REQUEST,
    id
});
export const deleteAchatReceive = (id) => ({
    type: TYPES.DELETE_ACHAT_RECEIVE,
    id
});

//get total achat
export const getTotalAchatRequest = (payload) => ({
    type: TYPES.GET_TOTAL_ACHAT_REQUEST,
    payload
});
export const getTotalAchatReceive = (payload) => ({
    type: TYPES.GET_TOTAL_ACHAT_RECEIVE,
    payload
});
