//const  myBaseUrl = "http://128.199.32.128/";

import moment from 'moment';

//const myBaseUrl = "http://localhost:3000/";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

//Random number < 100
const imageNumber = Math.floor(Math.random() * 100);

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 275;
export const appDrawerWidth = 320;
// USERS ROLES
const USER = 'USER';
const ADMIN = 'ADMIN';
const PARKMANAGER = 'PARKMANAGER';
const PLANNER = 'PLANNER';

// USERS STATUS
const ACTIVE = 'ACTIVE';
const BLOCKED = 'BLOCKED';
const INACTIVE = 'INACTIVE';
//const INACTIVE = 'INACTIVE';
const DELETED = 'DELETED';

// Engin Categories
const CAMION = 'CAMION';
const VOITURE = 'VOITURE';
const ENGIN = 'ENGIN';
const REMORQUE = 'REMORQUE';
const PLATEAU = 'PLATEAU';
const BENNE = 'BENNE';
const PORTECHARGE = 'PORTECHARGE';
const CITERNE = 'CITERNE';
const MINIBUS = 'MINIBUS';
const TRACTEUR = 'TRACTEUR';
const POLCAIN = 'POLCAIN';
const CHARGEUSE = 'CHARGEUSE';

//Engin Status
const AVAILABLE = 'AVAILABLE';
const UNAVAILABLE = 'UNAVAILABLE';
const IN_MAINTENANCE = 'IN_MAINTENANCE';

//Requests Status
const NEW = 'NEW';
const PENDING = 'PENDING';
const PLANED = 'PLANED';
const REJECTED = 'REJECTED';
const CANCELED = 'CANCELED';
const DONE = 'DONE';

//Requests Types
const IMMOBILIER = 'IMMOBILIER';
const TAPIS = 'TAPIS';
const MATELAS = 'MATELAS';
const AUTO = 'AUTO';

//Options Types
const SELECT = 'SELECT';
const SURFACE = 'SURFACE';
const NBPLACE = 'NBPLACE';
const NBPLACE_SURFACE = 'NBPLACE_SURFACE';
const TYPE_NBPLACE_SURFACE = 'TYPE_NBPLACE_SURFACE';

// Payment Types
const IN = 'IN';
const OUT = 'OUT';

export const enginCategories = [
    { id: CAMION, name: 'CAMION' },
    { id: VOITURE, name: 'VOITURE' },
    { id: ENGIN, name: 'ENGIN' },
    { id: REMORQUE, name: 'REMORQUE' },
    { id: PLATEAU, name: 'PLATEAU' },
    { id: BENNE, name: 'BENNE' },
    { id: PORTECHARGE, name: 'PORTECHARGE' },
    { id: CITERNE, name: 'CITERNE' },
    { id: MINIBUS, name: 'MINIBUS' },
    { id: TRACTEUR, name: 'TRACTEUR' },
    { id: POLCAIN, name: 'POLCAIN' },
    { id: CHARGEUSE, name: 'CHARGEUSE' }
];

export const enginStatus = [
    { id: AVAILABLE, name: 'Disponible' },
    { id: UNAVAILABLE, name: 'Indisponible' },
    { id: IN_MAINTENANCE, name: 'En maintenance' }
];

export const usersRoles = [
    { id: USER, name: 'Utilisateur' },
    { id: ADMIN, name: 'Administrateur' },
    { id: PARKMANAGER, name: 'Gestionnaire de parc' },
    { id: PLANNER, name: 'Planificateur' }
];

export const usersStatus = [
    { id: ACTIVE, name: 'Actif' },
    { id: BLOCKED, name: 'Bloqué' },
    { id: INACTIVE, name: 'Inactif' },
    { id: DELETED, name: 'Supprimé' }
];

export const requestsStatus = [
    { id: NEW, name: 'Nouveau' },
    { id: PENDING, name: 'En attente' },
    { id: PLANED, name: 'Planifié' },
    { id: REJECTED, name: 'Rejeté' },
    { id: CANCELED, name: 'Annulé' },
    { id: DONE, name: 'Terminé' }
];

export const requestsTypes = [
    { id: IMMOBILIER, name: 'Immobilier' },
    { id: TAPIS, name: 'Tapis' },
    { id: MATELAS, name: 'Matelas' },
    { id: AUTO, name: 'Véhicule' }
];

export const optionsTypes = [
    { id: SELECT, name: 'Sélection (Titre / Prix)' },
    { id: SURFACE, name: 'Surface (Longueur * Largeur / Prix)' },
    { id: NBPLACE, name: 'Nombre de place (Nombre de place / Prix)' },
    { id: NBPLACE_SURFACE, name: 'Nombre de place et surface (Nombre de place / Surface / Prix)' },
    { id: TYPE_NBPLACE_SURFACE, name: 'Type et nombre de place et surface (Type / Nombre de place / Prix)' }
];

export const projectsStatus = [{ id: DONE, name: 'Projets fini' }];

export const paymentTypes = [
    { id: IN, name: 'Entrée' },
    { id: OUT, name: 'Sortie' }
];
export const initialEngin = {
    id: null,
    categorie: '',
    nom: '',
    matricule: '',
    numCarteGrise: '',
    numChassis: '',
    kilometrage_NBHeure: 0,
    note: ''
};
export const initialFournisseur = {
    id: null,
    note: '',
    societe: '',
    responsable: '',
    adresse: '',
    evaluation: '',
    telephone: '',
    produit: ''
};

export const initialAchat = {
    fournisseursId: '',
    numcommande: '',
    ProductItems: [{ produitID: '', quantity: 0, tarif: 0 }]
};

export const initialMaintenance = {
    enginID: '',
    chauffeurID: '',
    outillage: '',
    mecanicien: '',
    emplacement: '',
    vehiculeID: '',
    type_Panne: '',
    dure_Indisp: null,
    kilometrage: null,
    note: '',
    date: '',
    remorqueId: '',
    PieceMaintenance: [{ pieceID: '', quantity: 0 }]
};

export const initialMatiere = {
    id: null,
    nom: '',
    ref: '',
    note: ''
};
export const initialFournisseurMatiere = {
    id: null,
    note: '',
    nom: '',
    adresse: '',
    numero: '',
    mail: '',
    produits: '',
    note: ''
};
export const initialClient = {
    id: null,
    categorie: '',
    Societe: '',
    responsable: '',
    numero: '',
    mail: '',
    note: '',
    paiement: '',
    note: ''
};
export const initialCle = {
    id: null,
    ref: '',
    code: '',
    duree: 0,
    note: ''
};
export const initialChauffeur = {
    id: null,
    nom: '',
    ddn: '',
    telephone: '',
    adresse: '',
    numSecurite: '',
    numPermis: ''
};
export const initialTourneeExterne = {
    id: null,
    chauffeurId: '',
    camionId: '',
    remorqueId: '',
    cleId: '',
    EtatKilometrage: 0,
    clientData: { clientId: '', produitId: '', tarification: 0, Balance: 0 },
    fournisseurData: { fournisseurId: '', produitId: '', tarification: 0, Balance: 0 },
    note: ''
};
export const initialPiece = {
    id: null,
    nom: '',
    etat_stock: '0,00'
};

export const initialAddress = {
    id: null,
    address: '',
    lat: '',
    lng: ''
};

export const initialImage = {
    id: null,
    filename: '',
    mimetype: '',
    encoding: ''
};

export const initialUser = {
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    address: '',
    role: '',
    image: `https://i.pravatar.cc/200?img=${imageNumber}`
};
export const initialAssurance = {
    id: null,
    date: 'yyyy-MM-ddThh:mm',
    societe: '',
    intervalle: 0,
    moment: 0,
    note: ''
};
export const initialTaxe = {
    id: null,
    date: 'YYYY-MM-DDTHH:mm',
    societe: '',
    intervalle: 0,
    moment: 0,
    note: '',
    enginId: ''
};
export const initialVisiteTechnique = {
    id: null,
    date: 'YYYY-MM-DDTHH:mm',
    societe: '',
    intervalle: 0,
    moment: 0,
    note: '',
    enginId: ''
};

export const intialEngin = {
    id: null,
    categorie: '0',
    nom: '',
    matricule: '',
    numCarteGrise: '',
    numChassis: '',
    date_circulation: moment().format('YYYY-MM-DDTHH:MM'),
    date_assurance: moment().format('YYYY-MM-DDTHH:MM'),
    date_vignette: moment().format('YYYY-MM-DDTHH:MM'),
    date_taxe: moment().format('YYYY-MM-DDTHH:MM'),
    caracteristique: '',
    note: '',
    status: ACTIVE
};

export const initialDemande = {
    id: null,
    title: '',
    date: moment().format('YYYY-MM-DDTHH:MM'),
    notes: '',
    address: initialAddress,
    requestItems: [
        {
            id: null,
            image: initialImage,
            serviceId: '0',
            optionId: '0',
            variableId: '0',
            quantity: 1,
            dimension: 1
        }
    ],
    customerId: '0',
    professionalId: null,
    source: 'WEB',
    discountVerified: false,
    discountCode: '',
    price: 0,
    priceAfterDiscount: 0
};

export const initialPayment = {
    id: null,
    type: 'OUT',
    date: moment().format('YYYY-MM-DDTHH:MM'),
    amount: 0,
    notes: '',
    transactionId: '',
    professionalId: '0'
};

export const initialDiscount = {
    id: null,
    code: '',
    value: 0,
    expirationDate: moment().format('YYYY-MM-DDTHH:MM')
};

export const initialTool = {
    id: null,
    title: '',
    description: '',
    address: initialAddress,
    status: AVAILABLE
};

export const initialService = {
    id: null,
    title: ''
};

export const initialOption = {
    id: null,
    title: '',
    serviceId: '0',
    isCategory: false,
    isInput: false
};

export const initialVariable = {
    id: null,
    title: '',
    minSurface: 0,
    maxSurface: 0,
    price: 0,
    optionId: '0'
};

export const optionTypes = [
    { id: 1, name: 'Surface' },
    { id: 2, name: 'Type de canapé' },
    { id: 3, name: 'Nombre de places' },
    { id: 4, name: 'Type de prestation' },
    { id: 5, name: 'Model de voiture' },
    { id: 6, name: 'Immatriculation' }
];

export const encodeUserNameAndPass = (userName, password) => {
    var decodedStringBtoA = `[userName]${userName}[password]${password}`;
    return btoa(decodedStringBtoA);
};

export const decodeUserNameAndPass = (str) => {
    return atob(str);
};

export const fileSupportedFormats = ['image/JPEG', 'image/JPG', 'image/jpg', 'image/png', 'image/PNG', 'image/jpeg'];

export const arabicMonths = ['يناير', 'فبراير', 'مارس', 'ابريل', 'مايو', 'يونيو', 'يوليو', 'اغسطس', 'سبتمبر', 'اكتوبر', 'نوفمبر', 'ديسمبر'];

export const arabicDays = ['الاحد', 'الاثنين', 'الثلاثاء', 'الاربعاء', 'الخميس', 'الجمعة', 'السبت'];
