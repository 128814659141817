import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import {
    dispatchSnackbarError
    // dispatchSnackbarSuccess,
} from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get vehicules
export function* getVehiculesSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getVehiculeRequest, payload);

        yield put(ACTIONS.getVehiculeReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError('Erreur lors de la récupération des véhicules');
    }
}

export function* vehiculesSagas() {
    yield takeLatest(TYPES.GET_VEHICULES_REQUEST, getVehiculesSaga);
}
