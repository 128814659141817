import * as TYPES from './types';
//Tools
// Get Tools
export const getToolsRequest = (payload) => ({
    type: TYPES.GET_TOOLS_REQUEST,
    payload
});
export const getToolsReceive = (payload) => ({
    type: TYPES.GET_TOOLS_RECEIVE,
    payload
});
//Get Tool
export const getToolRequest = (payload) => ({
    type: TYPES.GET_TOOL_REQUEST,
    payload
});
export const getToolReceive = (payload) => ({
    type: TYPES.GET_TOOL_RECEIVE,
    payload
});
//Create Tool
export const createToolRequest = (payload, closeModal) => ({
    type: TYPES.CREATE_TOOL_REQUEST,
    payload,
    closeModal
});
export const createToolReceive = (payload) => ({
    type: TYPES.CREATE_TOOL_RECEIVE,
    payload
});
//Update Tool
export const updateToolRequest = (payload, closeModal) => ({
    type: TYPES.UPDATE_TOOL_REQUEST,
    payload,
    closeModal
});
export const updateToolReceive = (payload) => ({
    type: TYPES.UPDATE_TOOL_RECEIVE,
    payload
});
//Delete Tool
export const deleteToolRequest = (id) => ({
    type: TYPES.DELETE_TOOL_REQUEST,
    id
});
export const deleteToolReceive = (id) => ({
    type: TYPES.DELETE_TOOL_RECEIVE,
    id
});
