import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get clients
export function* getClientsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getClientsRequest, payload);

        yield put(ACTIONS.getClientsReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError('Erreur lors de la récupération des clients');
    }
}

// Create client
export function* createClientSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createClientRequest, payload);
        yield put(ACTIONS.createClientReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Edit Client
export function* editClientSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.editClientRequest, payload);
        yield put(ACTIONS.editClientReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Client
export function* deleteClientSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteClientRequest, id);
        yield put(ACTIONS.deleteClientReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}

export function* clientsSagas() {
    yield takeLatest(TYPES.GET_CLIENTS_REQUEST, getClientsSaga);
    yield takeLatest(TYPES.CREATE_CLIENT_REQUEST, createClientSaga);
    yield takeLatest(TYPES.DELETE_CLIENT_REQUEST, deleteClientSaga);
    yield takeLatest(TYPES.EDIT_CLIENT_REQUEST, editClientSaga);
}
