import * as TYPES from './types';

const initialState = {
    TourneInterne_list: [],
    pageCount: 0,
    currentPage: 0,
    totalFiltred: 0,
    TourneInterne: {}
};

export default function tournesInterneReducer(state = initialState, action) {
    switch (action.type) {
        //Get tourne interne
        case TYPES.GET_TOURNESINTERNE_RECEIVE: {
            return {
                ...state,
                TourneInterne_list: action?.payload.data,
                pageCount: parseInt(action.payload?.total / 10),
                currentPage: parseInt(action.payload?.currentPage),
                totalFiltred: parseInt(action.payload?.total)
            };
        }
        //Create TourneInterne
        case TYPES.CREATE_TOURNEINTERNE_RECEIVE: {
            return {
                ...state,
                TourneInterne_list: [...state.TourneInterne_list, action.payload]
            };
        }
        //Edit TourneInterne
        case TYPES.EDIT_TOURNESINTERNE_RECEIVE: {
            return {
                ...state,
                TourneInterne: action.payload,
                TourneInterne_list: state.TourneInterne_list.map((TourneInterne) => {
                    if (TourneInterne.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return TourneInterne;
                    }
                })
            };
        }
        //Delete TourneInterne
        case TYPES.DELETE_TOURNEINTERNE_RECEIVE: {
            return {
                ...state,
                TourneInterne_list: state.TourneInterne_list.filter((TourneInterne) => TourneInterne.id !== action.id)
            };
        }

        default:
            return state;
    }
}
