// assets
import Inventory2Icon from '@mui/icons-material/Inventory2';
// constant
const icons = { Inventory2Icon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Planification = {
    id: 'Planification',
    title: '',
    type: 'group',
    roles: ['ADMIN', 'PLANNER'],
    children: [
        {
            id: 'planification',
            title: 'Planification',
            icon: icons.Inventory2Icon,
            type: 'collapse',
            children: [
                {
                    id: 'Liste des matières',
                    title: 'Matières',
                    type: 'item',
                    url: '/matiere',
                    breadcrumbs: true
                },
                {
                    id: 'liste des fournisseurs',
                    title: 'Fournisseurs',
                    type: 'item',
                    url: '/FournisseurMatiere',
                    breadcrumbs: true
                },
                {
                    id: 'Liste des clients',
                    title: 'Clients',
                    type: 'item',
                    url: '/client',
                    breadcrumbs: true
                },
                {
                    id: 'Planification de tournée',
                    title: 'Tournée externe',
                    type: 'item',
                    url: '/tourneExterne',
                    breadcrumbs: true
                },
                {
                    id: 'Planification interne',
                    title: 'Tournée interne',
                    type: 'item',
                    url: '/tourneInterne',
                    breadcrumbs: true
                }
            ]
        }
    ]
};

export default Planification;
