import { axiosInstance } from '../../network';

//Get cles
export const getClesPeageRequest = async (payload) => {
    return await axiosInstance.get(`ClePeage`, { params: payload });
};
//Create cle
export const createClePeageRequest = async (payload) => {
    return await axiosInstance.post(`ClePeage/create`, payload);
};

//Edit cle
export const editClePeageRequest = async (payload) => {
    return await axiosInstance.patch(`ClePeage/${payload.id}`, payload);
};
//Delete cle
export const deleteClePeageRequest = async (id) => {
    return await axiosInstance.delete(`ClePeage/delete/${id}`);
};
