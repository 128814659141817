import * as TYPES from './types';

// Get vehicule
export const getVehiculeRequest = (payload) => ({
    type: TYPES.GET_VEHICULES_REQUEST,
    payload
});
export const getVehiculeReceive = (payload) => ({
    type: TYPES.GET_VEHICULES_RECEIVE,
    payload
});
