import { axiosInstance } from '../../network';

//Get Pieces
export const getPiecesRequest = async (payload) => {
    return await axiosInstance.get(`piece`, { params: payload });
};

//Create Piece
export const createPieceRequest = async (payload) => {
    return await axiosInstance.post(`piece/createpiece`, payload);
};
//Edit Piece
export const editPieceRequest = async (payload) => {
    return await axiosInstance.patch(`piece/${payload.id}`, payload);
};
//Delete Piece
export const deletePieceRequest = async (id) => {
    return await axiosInstance.delete(`piece/delete/${id}`);
};

export const uploadFileRequest = (payload) => {
    // Create a FormData object to send the file
    const formData = new FormData();
    formData.append('file', payload);

    return axiosInstance.post('piece/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};
