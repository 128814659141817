//Get maintenance
export const GET_MAINTENANCES_REQUEST = 'GET_MAINTENANCES_REQUEST';
export const GET_MAINTENANCES_RECEIVE = 'GET_MAINTENANCES_RECEIVE';
//create maintenance
export const CREATE_MAINTENANCE_REQUEST = 'CREATE_MAINTENANCE_REQUEST';
export const CREATE_MAINTENANCE_RECEIVE = 'CREATE_MAINTENANCE_RECEIVE';
//delete maintenance
export const DELETE_MAINTENANCE_REQUEST = 'DELETE_MAINTENANCE_REQUEST';
export const DELETE_MAINTENANCE_RECEIVE = 'DELETE_MAINTENANCE_RECEIVE';
//Edit maintenance
export const EDIT_MAINTENANCE_REQUEST = 'EDIT_MAINTENANCE_REQUEST';
export const EDIT_MAINTENANCE_RECEIVE = 'EDIT_MAINTENANCE_RECEIVE';
