import { call, put, takeLatest } from 'redux-saga/effects';
import * as APIS from './apis';
import * as ACTIONS from './actions';
import * as TYPES from './types';
import { dispatchSnackbarError, dispatchSnackbarSuccess } from '../../utils/Shared';
import { setLoadingStatus } from 'store/loading/actions';

//Get Engins
export function* getEnginsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getEnginsRequest, payload);

        yield put(ACTIONS.getEnginsReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        yield put(setLoadingStatus(false));
    }
}

//Get Camion
export function* getCamionsSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getCamionsRequest, payload);

        yield put(ACTIONS.getCamionsReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError('Erreur lors de la récupération des Camions');
    }
}

//Get Remorque
export function* getRemorquesSaga({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getRemorquesRequest, payload);

        yield put(ACTIONS.getRemorquesReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        yield put(setLoadingStatus(false));
        dispatchSnackbarError('Erreur lors de la récupération des Remorques');
    }
}
// Create engin
export function* createEnginSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.createEnginRequest, payload);
        yield put(ACTIONS.createEnginReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        yield put(setLoadingStatus(false));
        dispatchSnackbarError(err.response?.data);
    }
}
//Update engin
export function* updateEnginSaga({ payload, closeModal }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.updateEnginRequest, payload);
        yield put(ACTIONS.updateEnginReceive(data));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
        setTimeout(() => {
            closeModal();
        }, 500);
    } catch (err) {
        yield put(setLoadingStatus(false));
        dispatchSnackbarError(err.response?.data);
    }
}

//Delete Engin
export function* deleteEnginSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        yield call(APIS.deleteEnginRequest, id);
        yield put(ACTIONS.deleteEnginReceive(id));
        yield put(setLoadingStatus(false));
        dispatchSnackbarSuccess('success');
    } catch (err) {
        dispatchSnackbarError(err.response?.data);
    }
}
// Get Historique de maintenance par engin id
export function* getHistoriqueSaga({ id }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.getHistoriqueEnginRequest, id);
        yield put(ACTIONS.getHistoriqueEnginReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError("Erreur lors de la récupération d'Historique de maintenance ");
    }
}

// Count Engin
export function* countEnginSaga() {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.countEnginRequest);
        yield put(ACTIONS.countEnginReceive(data));
        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError('Erreur ');
    }
}

// Count Broken Engin
export function* countBrokenEnginSaga() {
    try {
        yield put(setLoadingStatus(true));
        const { data } = yield call(APIS.countBrokenEnginRequest);
        yield put(ACTIONS.countBrokenEnginReceive(data));

        yield put(setLoadingStatus(false));
    } catch (err) {
        dispatchSnackbarError('Erreur ');
    }
}

export function* enginsSagas() {
    yield takeLatest(TYPES.GET_ENGINS_REQUEST, getEnginsSaga);
    yield takeLatest(TYPES.GET_CAMIONS_REQUEST, getCamionsSaga);
    yield takeLatest(TYPES.GET_REMORQUES_REQUEST, getRemorquesSaga);
    yield takeLatest(TYPES.CREATE_ENGIN_REQUEST, createEnginSaga);
    yield takeLatest(TYPES.DELETE_ENGIN_REQUEST, deleteEnginSaga);
    yield takeLatest(TYPES.GET_HISTORIQUE_REQUEST, getHistoriqueSaga);
    yield takeLatest(TYPES.UPDATE_ENGIN_REQUEST, updateEnginSaga);
    yield takeLatest(TYPES.COUNT_ENGIN_REQUEST, countEnginSaga);
    yield takeLatest(TYPES.COUNT_BROKEN_ENGIN_REQUEST, countBrokenEnginSaga);
}
